import React, {useState} from "react"

import { searchAllProjects } from "./FetchUtilities"
import { useAuth0 } from "../react-auth0-spa"
import { ProjectDataView } from "./ProjectDataView"
import { ApplicationStates } from "./Enums"
import _ from "lodash"

interface Props {
  changeDisplayPage: (page: ApplicationStates) => void
  setExtraProps: (obj: any) => void
}
/**
 * Controller and aggregator class for the AdjustmentGrants view
 * components, handles fetch and data flow
 */
export const ProjectDataContainer = (props: Props) => {
  const [projectsData, setProjectsData] = useState([])
  const { getTokenSilently } = useAuth0()

  const searchForProjects = (key: string, value: string) => {
    getTokenSilently().then((jwtToken: any) => {
      searchAllProjects(jwtToken, {[key]: value}).then((projects) => {
        if(_.isEmpty(projects)) {
          return setProjectsData(null)
        }
        return setProjectsData(projects)
      })
    })
  }

  return(
    <ProjectDataView
      projectsData = {projectsData}
      fetchProjectData = {searchForProjects}
      changeDisplayPage={props.changeDisplayPage}
      setExtraProps={props.setExtraProps}
    />
  )
}
