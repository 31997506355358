import * as _ from "lodash"

import {
  Project,
  ApprovalStatus,
} from "./Interfaces"

export const findProjectById = (projectId: string, projectList: Project[]) => {
  return _.find(projectList, {'id': projectId})
}

export const findProjectIndexById = (projectId: string, projectList: Project[]) => {
  return _.findIndex(projectList, {'id': projectId})
}

// Helper function to non-destructively apply a status change to a project
export const applyApprovalStatus = (newApprovalStatus: ApprovalStatus, project: Project) => {
  return Object.assign({}, project, {
    approvalStatus: newApprovalStatus
  })
}