import * as React from "react"

import { ApplicationStates } from "./Enums"
import { LoadingPlaceholder } from "./LoadingPlaceholder"
import  ProjectsContainer  from "./ProjectsContainer"
import { GeneratedGrantsContainer } from "./GeneratedGrantsContainer"
import { PeriodicGrantsContainer } from "./PeriodicGrantsContainer"
import  RegistrationContainer  from "./RegistrationContainer"
import  UploadPaymentsContainer  from "./UploadPaymentsContainer"
import { ReportingContainer } from "./ReportingContainer"
import { ProjectDataContainer } from "./ProjectDataContainer"
import  Header  from "./Header"
import { useAuth0 } from "../react-auth0-spa";
import { LoginContainer } from './LoginContainer'
import { RedemptionsContainer } from "./RedemptionsContainer"

/**
 * Class representing the top-level Dashboard display
 */
const SolarFusion: React.SFC<any> = () => {

  const {
    isAuthenticated,
    loginWithRedirect,
    // logout,
    getTokenSilently,
    loading,
  } = useAuth0();

  const [displayPage, changeDisplayPage] = React.useState(ApplicationStates.ProjectData)
  const [token, updateToken] = React.useState();
  const [extraProps, setExtraProps] = React.useState({});

  if(loading) {
    return (
      <LoginContainer>
        Loading...
      </LoginContainer>
    )
  }
  if(!isAuthenticated) {
    return (
      <LoginContainer>
        <button onClick={() => loginWithRedirect({})}>Log in</button>
      </LoginContainer>
    )
  }

  getTokenSilently().then((authToken) => updateToken(authToken));

  let displayComponent = null
    
  if(token == null) {
    return (
      displayComponent = <LoadingPlaceholder />
    )
  }
  
  if(displayPage === ApplicationStates.Projects) {
    displayComponent = <ProjectsContainer token={token} extraProps={extraProps}/>
  }
  else if(displayPage === ApplicationStates.AdjustmentGrants) {
    displayComponent = <GeneratedGrantsContainer />
  }

  else if(displayPage === ApplicationStates.PeriodicGrants) {
    displayComponent = <PeriodicGrantsContainer />
  }

  else if(displayPage === ApplicationStates.Registrations) {
    displayComponent = <RegistrationContainer token={token}/>
  }

  else if(displayPage === ApplicationStates.UploadPayments) {
    displayComponent = <UploadPaymentsContainer />
  }

  else if(displayPage === ApplicationStates.Reporting) {
    displayComponent = <ReportingContainer token={token}/>
  }

  else if(displayPage === ApplicationStates.ProjectData) {
    displayComponent = <ProjectDataContainer changeDisplayPage={changeDisplayPage} setExtraProps={setExtraProps} />
  }

  else if(displayPage === ApplicationStates.Redemptions) {
    displayComponent = <RedemptionsContainer />
  }

  else {
    displayComponent = <LoadingPlaceholder />
  }

  return(
    <div id="solarfusion-container">
      <Header changeDisplayPage={changeDisplayPage} displayPage={displayPage}/>
      {displayComponent}
    </div>
  )
}

export default SolarFusion