import * as React from "react"
import * as _ from "lodash"

import {DuplicatesModal} from "./DuplicatesModal";
import { ConfirmModal } from "./ConfirmModal"
import {Project, ApprovalStatus, ApprovalStatusContainer} from "./Interfaces"
import { ModalTypes, ProjectViewStyle } from "./Enums"

interface Props {
  modalType: number
  confirmModalData?: any
  duplicatesModalData?: any
  approvalStatuses: ApprovalStatusContainer
  handleSubmitFromConfirmModal: (newApprovalStatus: ApprovalStatus, project: Project) => void
  rejectFromDuplicateModal: (value: string, projectId: string, isBaseProject: boolean) => void
  updateActiveProjectId: (projectId: string) => void
  changeDisplayType: (displayStyle: ProjectViewStyle) => void
  toggleShowModal: (modalType?: ModalTypes) => void,
  closeConfirmModal: () => void;
}

export const ProjectModal = (props: Props) => {

  // Since statuses can change over time (and are returned from an endpoint), the best option that isn't
  // hardcoding is searching for the string in the statuses.
  const duplicateApprovalStatus = _.find(props.approvalStatuses.rejectionCodes, (status: ApprovalStatus) => {
    return status.title.includes("duplicate")
  })

  if(props.modalType === ModalTypes.ProjectConfirmModal) {
    return(
      <ConfirmModal
        newApprovalStatus={props.confirmModalData.newApprovalStatus}
        project = {props.confirmModalData.project}
        handleSubmitFromConfirmModal={props.handleSubmitFromConfirmModal}
        closeConfirmModal={props.closeConfirmModal}
      />
    )
  }
  else if(props.modalType === ModalTypes.DuplicatesModal) {
    // duplicates modal
    return(
      <DuplicatesModal
        baseProjectData = {props.duplicatesModalData.baseProject}
        potentialDuplicateProjects = {props.duplicatesModalData.potentialDuplicateProjects}
        handleCloseModal = {props.toggleShowModal}
        rejectFromDuplicateModal = {props.rejectFromDuplicateModal}
        duplicateApprovalStatus = {duplicateApprovalStatus}
        updateActiveProjectId = {props.updateActiveProjectId}
        changeDisplayType = {props.changeDisplayType}
        toggleShowModal={props.toggleShowModal}
        approvalStatuses={props.approvalStatuses}
      />
    )
  }
  }


export default ProjectModal
