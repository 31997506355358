import * as React from "react"
import styled from 'styled-components'

import GrantsTable from "./GrantsTable";
import { TableType } from "./Enums"
import { LoadingPlaceholder } from "./LoadingPlaceholder";

interface Props {
  redemptionGrantsData: any
}

const PeriodicGrantsViewContainer = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: space-around;
  padding: 2rem;
  font-size: 12px;
`

export const RedemptionGrantsView = (props: Props) => {

  const {
    redemptionGrantsData,
  } = props

  return(
    redemptionGrantsData[0] == null ? <LoadingPlaceholder /> :
      
    <div className="adjustment-grants-view-container">
      <PeriodicGrantsViewContainer>
        <GrantsTable
          inputData={redemptionGrantsData}
          tableType={TableType.REDEMPTION_GRANTS}
        />
      </PeriodicGrantsViewContainer>
    </div>
  )
}