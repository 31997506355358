import * as React from "react"
import Modal from "react-modal"
import * as _ from "lodash"

import { ProjectsTableView } from "./ProjectsTableView"
import { ProjectsDashboardView } from "./ProjectsDashboardView"
import { LoadingPlaceholder } from "./LoadingPlaceholder"
import { getDuplicateIds, getProjectsData, updateProject, setProjectApprovalStatus, setWalletAddress, getProjectDetails, getDupesForProject } from "./FetchUtilities"
import ProjectModal from "./ProjectModal"
import { applyApprovalStatus, findProjectById } from "./ProjectUtilities"
import { Project, ApprovalStatus, ApprovalStatusContainer } from "./Interfaces"
import { ProjectViewStyle, ModalTypes } from "./Enums"
import { getApprovalStatusByTitle, approvalCodes } from "./utils"
import { DuplicateMap } from "./DuplicateMap"

export interface Props {
  token: string;
  extraProps: {
    displayType?: number;
    activeProjectId?: string;
    projectsData?: Project[];
    fromSearch?: boolean;
  }
}

interface State {
  projectViewStyle: number
  isError: boolean
  shouldShowModal: boolean
  modalComponent: any
  activeProjectId: string
  displayType: number
  sortColumn: string
  sortDirection: string
  projectsData?: Project[]
  approvalStatuses?: ApprovalStatusContainer
  modalType?: any,
  duplicatesModalData?: any
  confirmModalData?: any
}

/**
 * Controller and aggregator class for the Projects view
 * components, handles fetch and data flow
 */
class ProjectsContainer extends React.Component<Props, State> {
  private duplicateMap: DuplicateMap;

  constructor(props: Props ) {
    super(props)

    let stateToSet = {
      projectViewStyle: ProjectViewStyle.Table,
      displayType: ProjectViewStyle.Table,
      activeProjectId: "",
      projectsData: null,
    };

    if (props.extraProps.fromSearch === true) {
      stateToSet = {
        projectViewStyle: ProjectViewStyle.Dashboard,
        displayType: ProjectViewStyle.Dashboard,
        activeProjectId: props.extraProps.activeProjectId,
        projectsData: props.extraProps.projectsData,
      }
    };
    
    this.state = {
      ...stateToSet,
      isError: false,
      modalComponent: null,
      sortColumn: 'createdAt',
      sortDirection: 'ASC',
      approvalStatuses: approvalCodes,
      shouldShowModal: false,
      modalType: null,
      duplicatesModalData: {
        baseProject: null,
        potentialDuplicateProjects: null
      },
      confirmModalData: {
        newApprovalStatus: null,
        project: null,
        isBaseProject: false,
      },
    }

    this.duplicateMap = new DuplicateMap(this.props.token);
  }

  // Projects data and duplicates data come from separate endpoints.
  // On mount, fetch both and put into state.
  async componentDidMount() {
    if(this.state.projectsData == null) {

      const projectsData = await getProjectsData(this.props.token);
      const duplicatesIdList = await getDuplicateIds(this.props.token);
      this.duplicateMap.updateMapWithAllDuplicates(duplicatesIdList);
      
      this.setState({
        ...this.state,
        projectsData: projectsData,
        approvalStatuses: approvalCodes,
      })
    }
  }

  changeDisplayType = (displayType: ProjectViewStyle) => {
    this.setState({
      displayType,
    })
  }

  updateActiveProjectId = (id: string) => {
    this.setState({
      activeProjectId: id,
    })
  }

  toggleShowModal = (modalType?: ModalTypes) => {
    const newToggle = !this.state.shouldShowModal;
    let newModalType = modalType;

    if (modalType == null) {
      newModalType = this.state.modalType;
    }

    this.setState({
      shouldShowModal: newToggle,
      modalType: newModalType,
    })
  }

  closeConfirmModal = () => {
    const newToggle = !this.state.shouldShowModal;

    if (this.state.confirmModalData.confirmFromDuplicatesModal) {
      this.changeModalType(ModalTypes.DuplicatesModal)
    } else {
      this.setState({
        shouldShowModal: newToggle,
        modalType: ModalTypes.ProjectConfirmModal
      })
    }
  }

  changeModalType = (modalType: ModalTypes) => {
    this.setState({
      modalType,
    })
  }

  showDuplicatesModal = async (projectId: string) => {
    const project = findProjectById(projectId, this.state.projectsData);
    

    const duplicates = await getDupesForProject(this.props.token, projectId);

    this.setState({
      duplicatesModalData: {
        baseProject: project,
        potentialDuplicateProjects: duplicates
      },
    })

    return this.toggleShowModal(ModalTypes.DuplicatesModal)
  }

  showConfirmModal = (newApprovalStatus: ApprovalStatus, project: Project, isBaseProject?: boolean, fromDuplicateModal?: boolean) => {

    this.setState({
      confirmModalData: {
        newApprovalStatus,
        project,
        isBaseProject: isBaseProject,
        confirmFromDuplicatesModal: fromDuplicateModal,
      },
    })

    if (fromDuplicateModal) {
      return this.changeModalType(ModalTypes.ProjectConfirmModal)
    } else {
      return this.toggleShowModal(ModalTypes.ProjectConfirmModal)
    }
  }

  submitFromConfirmModal = async (newApprovalStatus: ApprovalStatus, project: Project) => {
    // used for batch setting, if we choose to implement this later
    if (project[0] != null) {
      const promises = project.map((each: Project) => {
        const updatedProject = applyApprovalStatus(newApprovalStatus, each);
        return setProjectApprovalStatus(updatedProject, newApprovalStatus, this.props.token);
      });
      await Promise.all(promises);
      // set a single project
    } else {
      const updatedProject = applyApprovalStatus(newApprovalStatus, project);

      setProjectApprovalStatus(updatedProject, newApprovalStatus, this.props.token);
    }

    this.toggleShowModal(ModalTypes.ProjectConfirmModal);

    // if (this.state.confirmModalData.confirmFromDuplicatesModal !== true) {
    //   this.changeDisplayType(ProjectViewStyle.Table)
    // }

    let projectToRemove: Project;
    let newProjectsList: Project[];

    if(project[0] != null) {
      const projectIds = project.map((each) => each.id);

      projectToRemove = projectIds.map((each) => findProjectById(each, this.state.projectsData));
      newProjectsList = this.state.projectsData;
      projectToRemove.map((each) => {
        return newProjectsList = _.without(newProjectsList, each) as Project[];
      })
    } else {
      projectToRemove = findProjectById(project.id, this.state.projectsData);
      newProjectsList = _.without(this.state.projectsData, projectToRemove) as Project[];
    }


    this.setState({
      activeProjectId: newProjectsList[0] ? newProjectsList[0].id : null,
      projectsData: newProjectsList,
    });

    if (newProjectsList[0] == null) {
      this.changeDisplayType(ProjectViewStyle.Table)
    }
  }

  rejectFromDuplicateModal = async (value: string, projectId: string, isBaseProject: boolean) => {
    const rejectStatus = getApprovalStatusByTitle(value);
    const projectDetails = await getProjectDetails(this.props.token, projectId);

    const project = projectDetails.projectDetails[0];
    this.showConfirmModal(rejectStatus, project, isBaseProject, true);
  }

  saveEditedProject = async (updateData: Partial<Project>) => {
    await updateProject(this.props.token, updateData);
  }

  saveWalletAddress = async(projectId: string, walletAddress: string) => {
    await setWalletAddress(projectId, walletAddress, this.props.token)
  }

  // block render until all props are loaded
  render() {
    if(this.state.projectsData == null || this.state.approvalStatuses == null) {
      return <LoadingPlaceholder />
    }
    if(this.state.displayType === ProjectViewStyle.Table) {
      return(
        <>
        <Modal isOpen={this.state.shouldShowModal} contentLabel="modal">
          <ProjectModal 
          modalType = {this.state.modalType}
          confirmModalData = {this.state.confirmModalData}
          duplicatesModalData = {this.state.duplicatesModalData}
          approvalStatuses = {this.state.approvalStatuses}
          toggleShowModal = {this.toggleShowModal}
          handleSubmitFromConfirmModal = {this.submitFromConfirmModal}
          rejectFromDuplicateModal = {this.rejectFromDuplicateModal}
          updateActiveProjectId = {this.updateActiveProjectId}
          changeDisplayType = {this.changeDisplayType}
          closeConfirmModal = {this.closeConfirmModal}
          />
        </Modal>
        <ProjectsTableView
          projectsData = {this.state.projectsData}
          approvalStatuses = {this.state.approvalStatuses}
          showDuplicatesModal = {this.showDuplicatesModal}
          shouldShowModal = {this.state.shouldShowModal}
          modalComponent = {this.state.modalComponent}
          changeDisplayType = {this.changeDisplayType}
          updateActiveProjectId = {this.updateActiveProjectId}
          toggleShowModal = {this.toggleShowModal}
          rejectFromDuplicateModal = {this.rejectFromDuplicateModal}
          showConfirmModal = {this.showConfirmModal}
        />
        </>
      )
    }
    else if(this.state.displayType === ProjectViewStyle.Dashboard) {
      return(
        <>
        <Modal isOpen={this.state.shouldShowModal} contentLabel="modal">
          <ProjectModal 
          modalType = {this.state.modalType}
          confirmModalData = {this.state.confirmModalData}
          duplicatesModalData = {this.state.duplicatesModalData}
          approvalStatuses = {this.state.approvalStatuses}
          toggleShowModal = {this.toggleShowModal}
          handleSubmitFromConfirmModal = {this.submitFromConfirmModal}
          rejectFromDuplicateModal = {this.rejectFromDuplicateModal}
          updateActiveProjectId = {this.updateActiveProjectId}
          changeDisplayType = {this.changeDisplayType}
          closeConfirmModal = {this.closeConfirmModal}
          />
        </Modal>
        <ProjectsDashboardView
          projectsData = {this.state.projectsData}
          showConfirmModal = {this.showConfirmModal}
          approvalStatuses = {this.state.approvalStatuses}
          saveEditedProject = {this.saveEditedProject}
          shouldShowModal = {this.state.shouldShowModal}
          activeProjectId = {this.state.activeProjectId}
          updateActiveProjectId = {this.updateActiveProjectId}
          changeDisplayType = {this.changeDisplayType}
          showDuplicatesModal = {this.showDuplicatesModal}
          saveWalletAddress = {this.saveWalletAddress}
          token = {this.props.token}
          duplicateMap = {this.duplicateMap}
        />
        </>
      )
    }
  }
}

export default ProjectsContainer;