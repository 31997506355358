import React, {useState, useEffect} from "react"
import * as _ from "lodash"
import moment from "moment"

import { PeriodicGrantsView } from "./PeriodicGrantsView"
import { getPeriodicGrantsData } from "./FetchUtilities"
import { useAuth0 } from "../react-auth0-spa"
import { removeNonAsciiChars } from "./utils"


interface Props {
  token: string
}

interface State {
  periodicGrantsData: any
  isError: boolean
  grantPeriod: {grantMonth: number, grantYear: number}
  shouldShowModal: boolean
  modalComponent: any
}

interface GrantPeriod {
  grantMonth: number
  grantYear: number
}

/**
 * Controller and aggregator class for the PeriodicGrants view
 * components, handles fetch and data flow
 */
export const PeriodicGrantsContainer: React.FC = () => {

  const initialGrantPeriod = {grantMonth: moment().month() + 1, grantYear: moment().year()} as GrantPeriod
  const [isError, setIsError] = useState(false)
  const [periodicGrantsData, setPeriodicGrantsData] = useState([] as any[])
  const [grantPeriod, setGrantPeriod] = useState(initialGrantPeriod)
  const { getTokenSilently } = useAuth0()

  // Fetch grants on mount
  useEffect(() => {
    async function fetchPeriodicGrantsData() {
      const jwtToken = await getTokenSilently()
      const result = await getPeriodicGrantsData(grantPeriod, jwtToken);
      
      if(!result && isError === false) {
        setIsError(true)
      }

      else if (result) {
        setPeriodicGrantsData(result);
      }
    }
    fetchPeriodicGrantsData()
  }, [getTokenSilently, grantPeriod, isError]);

  // Method to alter the current month and year for periodic grants
  const switchDate = (newMonth: number, newYear: number) => {
    setPeriodicGrantsData([])
    setGrantPeriod({grantMonth: newMonth, grantYear: newYear})
  }

  const formatPeriodicGrants = (periodicGrantsData:any) => {
    if (periodicGrantsData === null) {
      return null
    }
    return _.flatten(
      periodicGrantsData.map((periodicGrantData: any) => {
        const grantTypes = ['grant', 'ndfGrant']
        return grantTypes
          .map((grantType: string ) => {
            return _.get(periodicGrantData, grantType, null)
          })
          .filter((value: any) => {
            return value !== null
          })
      })
    ).map((periodicGrant: any) => {
      // round to 2 decimal places
      if(periodicGrant.amount && periodicGrant.amount >= 0) {
        periodicGrant.amount = Math.max(periodicGrant.amount, 0.01).toFixed(2)
      }

      periodicGrant.guid = removeNonAsciiChars(periodicGrant.guid)
      return periodicGrant
    })
  }

  return(
    <PeriodicGrantsView
      periodicGrantsData = {formatPeriodicGrants(periodicGrantsData)}
      currentDate = {grantPeriod}
      switchDate = {switchDate}
    />
  )
}