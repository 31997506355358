import * as React from "react";

import { ApplicationStates } from './Enums';

/**
 * Rendering class for the dashboard-wide header
 */
interface Props {
  displayPage: any
  changeDisplayPage: (page: ApplicationStates) => void
}

interface Link {
  displayName: string
  linkId: number
}

const Header = (props: Props) => {

  const {
    changeDisplayPage,
    displayPage
  } = props

  const links = [
    {
      displayName: "Project Search",
      linkId: ApplicationStates.ProjectData
    },
    {
      displayName: "New Project Review",
      linkId: ApplicationStates.Projects
    },
    {
      displayName: "Generated Grants",
      linkId: ApplicationStates.AdjustmentGrants
    },
    
    
  ]

  function handleClick(newPage: number) {
    changeDisplayPage(newPage)
  }

  return(
    <div id="solarfusion-header">
      <div className="solarfusion-header-logo">
        SF
      </div>
      <div className="solarfusion-header-menu">
        {links.map((link: Link) => {
          const className = "solarfusion-header-menu-clickable-item " + (displayPage === link.linkId ? "highlight" : "")
          return(
            <div className="solarfusion-header-menu-item" onClick={() => {handleClick(link.linkId)}} key={link.linkId}>
              <span className={className}>{link.displayName}</span>
            </div>
          )
        })}

      </div>
    </div>
  )
}

export default Header

