import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';

import { ApprovalStatusContainer, ApprovalStatus } from './Interfaces';

interface Props {
  approvalStatuses: ApprovalStatusContainer;
  handleRejectClick: (event: any) => void;
  inModal: boolean
}

export default function RejectButton(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  type Size = "large" | "small" | "medium";

  let size: Size = "large";
  let buttonId = "rejectButton";

  if (props.inModal) {
    size = "small"
    buttonId = "rejectButtonInModal"
  }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" variant="contained" id={buttonId} size={size} onClick={handleClick}>
        Reject
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.approvalStatuses.rejectionCodes.map((value: ApprovalStatus) => {
          return (<MenuItem value={value.title} key={value.title} onClick={() => {
            handleClose()
            return props.handleRejectClick({target: {value: value.title}})}
          }>{value.title}</MenuItem>)
        })}
      </Menu>
    </div>
  );
}
