import * as React from "react"

interface Props {
  fileUrl: string
}

// This is a stateful component in case we want to do a lightbox in the future
interface State {
}

export class PDFComponent extends React.Component<Props, State> {

  render() {
    return (
      <div className="document-list">
        <a href={this.props.fileUrl} target="_blank" rel="noopener noreferrer"><i className="fa fa-file-pdf-o document-icon"></i><span>PDF</span></a>
      </div>
    )
  }
}



