import * as React from "react"
import * as _ from "lodash"
import {Button} from "@material-ui/core";

import { LoadingPlaceholder } from "./LoadingPlaceholder"
import { ProjectEditFields } from "./ProjectEditFields"
import { MapPanel } from "./MapPanel"
import { DocumentPanel } from "./DocumentPanel"
import { AlertsPanel } from "./AlertsPanel"
import { ProjectNav } from "./ProjectNav"
import { ProjectViewStyle } from "./Enums"
import clone from "clone"
import { findProjectById, findProjectIndexById } from "./ProjectUtilities"
import RejectButton from "./RejectButton";
import { Project, ApprovalStatus, ApprovalStatusContainer } from "./Interfaces"
import { getApprovalStatusByTitle } from "./utils";
import { DuplicateMap } from "./DuplicateMap";
import { getProjectChangelog } from "./FetchUtilities";
import { ProjectChangelogPanel } from "./ProjectChangelogPanel";

interface Props {
  projectsData: Project[]
  approvalStatuses: ApprovalStatusContainer
  saveEditedProject: (project: Project) => void
  showConfirmModal: (approvalStatus: ApprovalStatus, project: Project) => void
  shouldShowModal: boolean
  activeProjectId: string
  updateActiveProjectId: (newId: string) => void
  changeDisplayType: (displayType: ProjectViewStyle) => void
  showDuplicatesModal: (projectId: string) => void
  saveWalletAddress: (projectId: string, walletAddress: string) => void
  token: string
  duplicateMap: DuplicateMap
}

interface State {
  editedProjectData: Project
  isEditMode: boolean
  isOpen: boolean
}

export const ProjectsDashboardView = (props: Props) => {
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [editedProjectData, setEditedProjectData] = React.useState(clone(findProjectById(props.activeProjectId, props.projectsData)));
  const [hasDupe, setHasDupe] = React.useState(false);
  const [projectChangelog, setProjectChangelog] = React.useState([]);
  const [updatedWalletAddress, setUpdatedWalletAddress] = React.useState("")

  React.useEffect(() => {
    const newActiveProject = props.projectsData[findProjectIndexById(props.activeProjectId, props.projectsData)];
    
    getProjectChangelog(props.token, props.activeProjectId).then((res) => setProjectChangelog(res));
    setEditedProjectData(newActiveProject);

    props.duplicateMap.hasDuplicate(editedProjectData.id).then((res) => setHasDupe(res));
  },[editedProjectData, props.activeProjectId, props.duplicateMap, props.projectsData, props.showDuplicatesModal, props.token])

  const updateField = (field: string, value: string) => {
    setEditedProjectData(_.set(editedProjectData, field, value))
  }

  const handleWalletAddressChange = (e) => setUpdatedWalletAddress(e.currentTarget.value)

  const setEditMode = (newEditModeState: boolean) => {
    setIsEditMode(newEditModeState)
  }

  // After editing a project via the dialog, save changes 
  // to the database even without an approval/rejection
  // in order to not lose changes 
  const handleSave = () => {
    props.saveEditedProject(editedProjectData);
    setIsEditMode(false);
  }

  // To cancel an edit, restore the original (parent) copy by cloning
  // into the editedProjectData 
  const handleCancel = () => {
    setIsEditMode(false);
    setEditedProjectData(clone(findProjectById(props.activeProjectId, props.projectsData)))
  }

  // Since we are now operating (mostly) on Project ID strings, need to do some acrobatics to get the index of the prev
  // and next projects for flipping through on the dashboard view
  const setNextProjectActive = () => {
    const newActiveProject = props.projectsData[findProjectIndexById(props.activeProjectId, props.projectsData) + 1];
    props.updateActiveProjectId(newActiveProject.id);
    setEditedProjectData(newActiveProject)
  }

  const setPrevProjectActive = () => {
    const newActiveProject = props.projectsData[findProjectIndexById(props.activeProjectId, props.projectsData) - 1];
    props.updateActiveProjectId(newActiveProject.id);
    setEditedProjectData(newActiveProject)
  }

  // convenience method to wrangle the various ApprovalStatuses, since the <option> only carries the
  // approval status text when clicked
  const handleRejectClick = (event: any) => {
    const rejectStatus = getApprovalStatusByTitle(event.target.value)
    props.showConfirmModal(rejectStatus, editedProjectData)
  };
    
    if(props.projectsData && editedProjectData) {
      return (
        <div className="project-dashboard-view-container">
          <div className="view-switch">
            <ProjectNav
              activeProjectIndex={_.findIndex(props.projectsData, {'id': props.activeProjectId})}
              totalProjectCount={props.projectsData.length}
              setNextProjectActive={setNextProjectActive}
              setPrevProjectActive={setPrevProjectActive}
            />
            <div className="view-switch-button" onClick={() => props.changeDisplayType(ProjectViewStyle.Table)}>
              Switch to grid layout
            </div>
          </div>

          <div className="project-dashboard-row">
            <div className="panel project-dashboard-edit-form">
              <div className="panel-header">Project Data</div>
              <ProjectEditFields
                isEditMode={isEditMode}
                projectData={editedProjectData}
                updateField={updateField}
              />
              <div className = "modal-row">
                <div className = "modal-row-label">Approval status:</div> <div className = "modal-row-static-value"> {editedProjectData.approvalStatusCode}</div>
              </div>
              <div className="project-modal-edit">
                
              </div>
              <div className="project-dashboard-edit-form-action-buttons">
                {
                editedProjectData.approvalStatusCode === 'P' ? 
                  isEditMode === true ?
                    <div>
                      <div className="project-form-cancel-button" onClick={handleCancel}>
                      <Button variant="contained" id="defaultButton" size="large">
                        Cancel
                      </Button>
                      </div>
                      <div className="project-form-save-button" onClick={handleSave}>
                      <Button variant="contained" id="positiveButton" size="large">
                        Save
                      </Button>
                      </div>
                    </div>
                    :
                    <div>
                      <div className="project-form-edit-button" onClick={setEditMode.bind(null, true)}>
                      <Button variant="contained" id="defaultButton" size="large">
                        Edit
                      </Button>
                      </div>
                      <div className="project-form-approve-button" onClick={() => props.showConfirmModal(props.approvalStatuses.approvalCodes[0], editedProjectData)}>
                      <Button variant="contained" id="positiveButton" size="large">
                        Approve
                      </Button>
                      </div>

                      <div className="project-form-reject-button">
                      <RejectButton
                      approvalStatuses={props.approvalStatuses}
                      handleRejectClick={handleRejectClick}
                      inModal={false}
                      />

                    </div>
                  </div>
                : 
                isEditMode === true ?
                    <div>
                      <div className="project-form-cancel-button" onClick={handleCancel}>
                      <Button variant="contained" id="defaultButton" size="large">
                        Cancel
                      </Button>
                      </div>
                      <div className="project-form-save-button" onClick={handleSave}>
                      <Button variant="contained" id="positiveButton" size="large">
                        Save
                      </Button>
                      </div>
                    </div>
                    :
                <div>
                  <div className="project-form-edit-button" onClick={setEditMode.bind(null, true)}>
                  <Button variant="contained" id="defaultButton" size="large">
                    Edit
                  </Button>
                  </div>
                  {editedProjectData.approvalStatusCode === 'A' ?
                  <div className="project-form-edit-button">
                  <Button variant="contained" id="defaultButton" size="large" disabled={true}>
                    Approve
                  </Button>
                  </div>
                  :
                  <div className="project-form-approve-button" onClick={() => props.showConfirmModal(props.approvalStatuses.approvalCodes[0], editedProjectData)}>
                  <Button variant="contained" id="positiveButton" size="large">
                    Approve
                  </Button>
                  </div>
    }

                  <div className="project-form-reject-button">
                  <RejectButton
                  approvalStatuses={props.approvalStatuses}
                  handleRejectClick={handleRejectClick}
                  inModal={false}
                  />
                
                </div>
                <div>
                  <div>Update wallet address</div>
                  <input value={updatedWalletAddress} onChange={handleWalletAddressChange}></input>
                  <div className="project-form-approve-button" onClick={() => props.saveWalletAddress(props.activeProjectId, updatedWalletAddress)}>
                  <Button variant="contained" id="positiveButton" size="large">
                    Update
                  </Button>
                  </div>
                </div>
                </div>
                
                
                
                }

              </div>
            </div>
            <div className="panel project-dashboard-map-panel-container">
              <div className="project-dashboard-map-panel">
                <MapPanel address={editedProjectData.address + ", " + editedProjectData.city + ", " + editedProjectData.country} isEditMode={isEditMode}/>
              </div>
            </div>
          </div>
          <div className="project-dashboard-row">
            <div className="panel project-dashboard-document-panel-container">
              <div className="project-dashboard-document-panel">
                <DocumentPanel fileUrl={editedProjectData.documentation}/>
              </div>
            </div>

          <div className="panel project-dashboard-alerts-panel-container">
            <AlertsPanel
              project={editedProjectData}
              showDuplicatesModal={props.showDuplicatesModal}
              duplicateMap={props.duplicateMap}
              hasDuplicate={hasDupe}
              />
          </div>
        </div>

        <div className="project-dashboard-row">
            <div className="panel project-dashboard-document-panel-container">
              <div className="project-dashboard-document-panel">
                <ProjectChangelogPanel changelog={projectChangelog}/>
              </div>
            </div>
        </div>

      </div>
    )
  }
  else {
    return(
      <div className="project-dashboard-view-container">
        <LoadingPlaceholder />
      </div>
    )
  }
}