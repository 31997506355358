import { getDupesForProject } from "./FetchUtilities";

export class DuplicateMap {
  private map: Map<string, boolean>;
  private token: string;

  constructor(token: string) {
    this.map = new Map<string, boolean>()
    this.token = token;
  }

  updateMapForProject(id: string, duplicates: string[]) {
    this.map.set(id, true);
    duplicates.forEach((duplicateId) => this.map.set(duplicateId, true))
  }

  updateMapWithAllDuplicates(duplicates: string[]) {
    if(duplicates) {
      duplicates.forEach((duplicateId) => this.map.set(duplicateId, true))
    }
  }

  async hasDuplicate(id: string) {
    const foundDuplicate = this.map.get(id);

    if(foundDuplicate != null) {
      return foundDuplicate;
    }

    const duplicates = await getDupesForProject(this.token, id);

    if(duplicates == null) {
      return false;
    }

    this.updateMapForProject(id, duplicates);

    return true;
  }
}