import * as React from "react";

import {ApprovalStatus, Project} from "./Interfaces";
import { Button } from "@material-ui/core";

interface Props {
  newApprovalStatus: ApprovalStatus
  project: Project
  handleSubmitFromConfirmModal: (newApprovalStatus: ApprovalStatus, project: Project) => void
  closeConfirmModal: () => void,
}

export const ConfirmModal = (props: Props) => {

  const {
    newApprovalStatus,
    project,
    handleSubmitFromConfirmModal,
    closeConfirmModal,
  } = props

  let confirmMessage = null

  // show the action in the confirm dialog for better UX
  if (newApprovalStatus.code === "A") {

    confirmMessage = project[0] == null ?
      <div>Are you sure you want to <span className={"approval-confirm-message"}>approve</span> this project?</div>
      : <div>Are you sure you want to <span className={"approval-confirm-message"}>approve</span> these projects?</div>
  }
  else {
    confirmMessage = project[0] == null ? <div>
      <div>Are you sure you want to <span className={"rejection-confirm-message"}>reject</span> this project?</div>
      <div>Rejection reason: {newApprovalStatus.title}</div>
    </div>
    : <div>
    <div>Are you sure you want to <span className={"rejection-confirm-message"}>reject</span> these projects?</div>
    <div>Rejection reason: {newApprovalStatus.title}</div>
  </div>
  }

  return (
    <div className="project-modal-confirm">
      {confirmMessage}
      <div className="action-buttons">
        <div className="approve-button" onClick={() => handleSubmitFromConfirmModal(newApprovalStatus, project)}>
        <Button variant="contained" id="positiveButton" size="large">
          Submit
        </Button>
        </div>
        <div className="csv-button" onClick={() => closeConfirmModal()}>
        <Button variant="contained" id="defaultButton" size="large">
          Cancel
        </Button>
        </div>
      </div>
    </div>
  )
}