import * as React from "react"

interface Props {
  activeProjectIndex: number
  totalProjectCount: number
  setNextProjectActive: () => void
  setPrevProjectActive: () => void
}

export const ProjectNav = (props: Props) => {

  const {
    activeProjectIndex,
    totalProjectCount,
    setNextProjectActive,
    setPrevProjectActive
  } = props

  return(
    <div className="project-navigation">
      {activeProjectIndex > 0 ?
        <div onClick={setPrevProjectActive}>
          <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
        </div>
        :
        <i className="fa fa-arrow-circle-left grayed-out" aria-hidden="true"></i>
      }
      <div>Project {activeProjectIndex + 1} of {totalProjectCount}</div>
      {activeProjectIndex < totalProjectCount - 1 ?
        <div onClick={setNextProjectActive}>
          <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
        </div>
        :
        <i className="fa fa-arrow-circle-right grayed-out" aria-hidden="true"></i>
      }
    </div>
  )
}

