import React from 'react';
import {
  createMuiTheme,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'

import { TableType } from './Enums';
import { Order, HeadCell, Project, Registration } from './Interfaces';
import { getApprovalStatusTitleByCode } from './utils';
import RejectButton from './RejectButton';

export const getTableTitle = (tableType: TableType) => {
  switch(tableType) {
    case TableType.DUPLICATES_VIEW:
      return "Potential Duplicates:"
    case TableType.BASE_PROJECT_VIEW:
      return "Project Details:"
    case TableType.ADJUSTMENT_GRANTS:
      return "Adjustment Grants + NDF Grants:"
    case TableType.BOUNTY_GRANTS:
      return "Bounty Grants:"
    case TableType.PERIODIC_GRANTS:
      return "Periodic Grants:"
    case TableType.GRANTS:
      return "Grants:"
    case TableType.PROJECTS_SEARCH_RESULT:
      return "Results:"
    case TableType.REGISTRATIONS:
      return "Registrations:";
    case TableType.REDEMPTION_GRANTS:
      return "Redemption Grants:";
    case TableType.NDF_GRANTS:
      return "NDF Grants:"
    case TableType.GENERATED_GRANTS:
      return "Generated Grants:"
    default:
      return "Projects Data:"
  }
}

export const formatProjectsForTable = (projects: Project[], tableType?: TableType, approvalStatuses?: any, rejectButtonAction?: any) => {
  if (tableType != null) {
    if (tableType === TableType.DUPLICATES_VIEW || tableType === TableType.BASE_PROJECT_VIEW) {
      return projects.map((project) => {
        const approvalStatus = getApprovalStatusTitleByCode(project.approvalStatusCode);
  
        return {
          id: project.id,
          email: project.claimant.email,
          createdAt: project.createdAt,
          firstName: project.claimant?.firstName,
          lastName: project.claimant?.lastName,
          nameplate: project.nameplate,
          installDate: project.installDate,
          address: project.address,
          city: project.city,
          state: project.state,
          zipCode: project.zipCode,
          country: project.country,
          documentation: project.documentation,
          walletAddress: project.walletAddress,
          approvalStatus,
          affiliate: project.affiliateName,
          rejectButton: approvalStatus !== 'Pending' ? 'Project not pending, click to open' : (
            <div className="project-form-reject-button-table">
              <RejectButton
                approvalStatuses={approvalStatuses}
                handleRejectClick={(event) => rejectButtonAction(event, project.id)}
                inModal={true}
              />
            </div>
          )
        }
      })
    }
  }

  return projects.map((project) => {
    console.log({project})
    return {
    id: project.id,
    email: project.claimant?.email,
    createdAt: project.createdAt,
    firstName: project.claimant?.firstName,
    lastName: project.claimant?.lastName,
    nameplate: project.nameplate,
    installDate: project.installDate,
    address: project.address,
    city: project.city,
    state: project.state,
    zipCode: project.zipCode,
    country: project.country,
    documentation: project.documentation,
    walletAddress: project.walletAddress,
    approvalStatus: getApprovalStatusTitleByCode(project.approvalStatusCode),
    affiliate: project.affiliateName,
  }
  })
}

export const formatRegistrationsForTable = (registrations: Registration[]) => {
  return registrations.map((registration) => ({
    id: registration.id,
    email: registration.email,
    createdAt: registration.createdAt,
    firstName: registration.firstName,
    lastName: registration.lastName,
    nameplate: registration.nameplate,
    installDate: registration.installDate,
    address: registration.address,
    city: registration.city,
    state: registration.state,
    zipCode: registration.zip,
    country: registration.country,
    documentation: registration.documentation,
    walletAddress: registration.walletAddress,
    affiliate: registration.Affiliate_id,
    approvalStatus: registration.approvalStatus.title,
  }))
}

export const getTableHeaders = (tableType: TableType) => {
  const projectHeadCells = [
    { id: 'id', name: 'id', label: 'Id' },
    { id: 'email', name: 'email', label: 'Email' },
    { id: 'createdAt', name: 'createdAt', label: 'Claim date' },
    { id: 'firstName',name: 'firstName', label: 'First Name' },
    { id: 'lastName', name: 'lastName', label: 'Last Name' },
    { id: 'nameplate', name: 'nameplate', label: 'Nameplate' },
    { id: 'installDate', name: 'installDate', label: 'Install Date' },
    { id: 'address', name: 'address', label: 'Address' },
    { id: 'city', name: 'city', label: 'City' },
    { id: 'state', name: 'state', label: 'State' },
    { id: 'zipCode', name: 'zipCode', label: 'Post Code' },
    { id: 'country', name: 'country', label: 'Country' },
    { id: 'walletAddress', name: 'walletAddress', label: 'Wallet Address' },
    { id: 'affiliate', name: 'affiliate', label: 'Affiliate' },
    { id: 'approvalStatus', name: 'approvalStatus', label: 'Approval Status' },
    { id: 'documentation', name: 'documentation', label: 'Docs' },
  ];

  const generatedGrantHeadCells = [
    { id: 'id', name: 'id', label: 'grantId' },
    { id: 'guid', name: 'guid', label: 'guid' },
    { id: 'amount', name: 'amount', label: 'Amount', options: { sortCompare: (order) => {
      return (obj1, obj2) => {
        let val1 = parseInt(obj1.data, 10);
        let val2 = parseInt(obj2.data, 10);
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }} },
    { id: 'grantDate', name: 'grantDate', label: 'Grant Date' },
    { id: 'projectId', name: 'projectId', label: 'Project Id' },
    { id: 'typeTag', name: 'typeTag', label: 'Type Tag' },
    { id: 'walletAddress', name: 'walletAddress', label: 'Wallet Address' },
  ];

  const registrationHeadCells = [
    { id: 'id', name: 'id', label: 'Id' },
    { id: 'email', name: 'email', label: 'Email' },
    { id: 'firstName', name: 'firstName', label: 'First Name' },
    { id: 'lastName', name: 'lastName', label: 'Last Name' },
    { id: 'nameplate', name: 'nameplate', label: 'Nameplate' },
    { id: 'installDate', name: 'installDate', label: 'Install Date' },
    { id: 'address', name: 'address', label: 'Address' },
    { id: 'city', name: 'city', label: 'City' },
    { id: 'state', name: 'state', label: 'State' },
    { id: 'zip', name: 'zip', label: 'Post Code' },
    { id: 'country', name: 'country', label: 'Country' },
    { id: 'walletAddress', name: 'walletAddress', label: 'Wallet Address' },
    { id: 'affiliateCodename', name: 'affiliateCodename', label: 'Affiliate' },
    { id: 'approvalStatus', name: 'approvalStatus', label: 'Approval Status' },
    { id: 'documentation', name: 'documentation', label: 'Docs' },
  ];

  const redemptionHeadCells = [
    { id: 'id', name: 'id', label: 'Id' },
    { id: 'createdAt', name: 'createdAt', label: 'Created At' },
    { id: 'affiliateId', name: 'affiliateId', label: 'Affiliate Id' },
    { id: 'projectId', name: 'projectId', label: 'Project Id' },
    { id: 'existingWallet', name: 'existingWallet', label: 'Existing Wallet' },
    { id: 'newWallet', name: 'newWallet', label: 'New wallet' },
    { id: 'balance', name: 'balance', label: 'Balance' },
    { id: 'status', name: 'status', label: 'Status' },
  ];
  
  switch(tableType) {
    case TableType.DUPLICATES_VIEW:
      return [
        {id: 'rejectButton', name: 'rejectButton', label: 'Reject'},
        ...projectHeadCells,
      ]
    case TableType.BASE_PROJECT_VIEW:
      return [
        {id: 'rejectButton', name: 'rejectButton', label: 'Reject'},
        ...projectHeadCells,
      ]
    case TableType.ADJUSTMENT_GRANTS:
      return generatedGrantHeadCells
    case TableType.BOUNTY_GRANTS:
      return generatedGrantHeadCells
    case TableType.PERIODIC_GRANTS:
      return generatedGrantHeadCells
    case TableType.GRANTS:
      return generatedGrantHeadCells
    case TableType.GENERATED_GRANTS:
      return generatedGrantHeadCells
    case TableType.NDF_GRANTS:
      return generatedGrantHeadCells
    case TableType.PROJECTS_SEARCH_RESULT:
      return [
        {id: 'rejectButton', name: 'rejectButton', label: 'Reject'},
        ...projectHeadCells,
      ]
    case TableType.REGISTRATIONS:
      return registrationHeadCells;
    case TableType.REDEMPTION_GRANTS:
      return redemptionHeadCells;
    default:
      return projectHeadCells
  }
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const getWidths = (id: string) => {
  let maxWidth: string;
  if (id === "documentation") {
    maxWidth = "550px"
  } else if (id === "walletAddress") {
    maxWidth = "240px"
  } else if (id === "email") {
    maxWidth = "190px"
  } else if (id === "guid") {
    maxWidth = "250px"
  } else {
    maxWidth = "170px"
  }
  let minWidth: string;
  if (id === "email") {
    minWidth = "190px"
  } else if (id === "firstName") {
    minWidth = "120px"
  } else if (id === "city") {
    minWidth = "120px"
  } else if (id === "guid") {
    minWidth = "200px"
  } else if (id === "address") {
    minWidth = "120px"
  } else if (id === "state") {
    minWidth = "120px"
  } else if (id === "documentation") {
    minWidth = "340px"
  } else if (id === "rejectButton") {
    minWidth = "60px"
  } else {
    minWidth = "90px"
  }

  return {
    maxWidth,
    minWidth,
  }
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[]
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell) => {
          return (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              backgroundColor: "#707070",
              color: "white",
              fontSize: 14,
              padding: "10px"
            }}
          >
            
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span style={{
                  border: 0,
                  clip: 'rect(0 0 0 0)',
                  height: 1,
                  margin: -1,
                  overflow: 'hidden',
                  padding: 0,
                  position: 'absolute',
                  top: 20,
                  width: 1,
                }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        )})}
      </TableRow>
    </TableHead>
  );
}

export const getMuiTheme = () => createMuiTheme({
  overrides: {
    MuiTableHead: {
      root: {
        backgroundColor: "#707070 !important",
        fontSize: 'small',
        fontWeight: 'bold',
        color: 'white',
      }
    },
    // @ts-ignore - not in type but definitely required
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: "#707070 !important",
        fontSize: 'small',
        fontWeight: 'bold',
        color: 'white',
      }
    }
  }
});