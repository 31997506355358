import * as React from "react"
import { CSVLink } from 'react-csv'
import { Button } from "@material-ui/core"

import {ReportTypes} from "./Enums"

interface Props {
  allRegistrationsReportData: any
  buildReport: (reportType: number) => void
}

export const ReportingView = (props: Props) => {

  const {
    allRegistrationsReportData,
    buildReport
  } = props

  const allRegistrationsHeaders = [
    "id",
    "email",
    "firstName",
    "lastName",
    "address",
    "address2",
    "city",
    "zipCode",
    "country",
    "nameplate",
    "installDate",
    "documentationLink",
    "claimantWalletAddress",
    "referralEmail",
    "referralWallet",
    "approvalStatus",
    "actualKwh",
    "duplicateIndicator"
  ]

  const handleClick = (reportType: number) => {
    buildReport(reportType)
  }

  return(
    <div className="reporting-view-container">
      <div className="reporting-view-header">
      </div>
        <div className={"reporting-view-buttons"}>
          {allRegistrationsReportData ?
            <Button variant="contained" id="positiveButton" size="medium" onClick={handleClick.bind(null, ReportTypes.AllRegistrationsReport)}>
              <CSVLink data={allRegistrationsReportData} headers={allRegistrationsHeaders} filename={'allRegistrations.csv'}>
                <div>Download All Registrations</div>
              </CSVLink>
            </Button>
            :
            <Button variant="contained" id="defaultButton" size="medium" onClick={handleClick.bind(null, ReportTypes.AllRegistrationsReport)}>Generate All Registrations</Button>
          }
        </div>
    </div>
  )
}
