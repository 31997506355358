import * as superagent from "superagent";

import {
  Project,
  Registration,
  ApprovalStatus,
} from "./Interfaces";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const PROJECTS_URL = BASE_URL + "/projects/pending";
const DUPLICATES_URL = BASE_URL + "/projects/duplicates";
const UPDATE_PROJECT_URL = BASE_URL + "/projects/update";
const APPROVE_PROJECT_URL = BASE_URL + "/projects/updateApprovalStatus";
const SET_WALLET_URL = BASE_URL + "/projects/setwalletaddress";
const APPROVE_URL = BASE_URL + "/granting/approve";
const ADJUSTMENT_GRANTS_URL = BASE_URL + "/granting/prepareAdjustmentGrants";
const GENERATED_GRANTS_URL = BASE_URL + "/grants/getGeneratedGrants"
const PERIODIC_GRANTS_URL = BASE_URL + "/granting/preparePeriodicGrants";
const REDEMPTION_GRANTS_URL = BASE_URL + "/granting/prepareRedemptionGrants";
const APPROVAL_STATUSES_URL = BASE_URL + "/approval/codes";
const REGISTRATIONS_URL = BASE_URL + "/registrations";
const UPLOAD_CSV_URL = BASE_URL + "/uploadPaidGrants";
const PROJECT_DATA_URL = BASE_URL + "/projects/data"
const PROJECT_URL = BASE_URL + "/projects";

const fetchDataGet = async (url: string, token: string) => {
  return superagent
    .get(url)
    .set("Authorization", `Bearer ${token}`)
    .set("Accept", "application/json")
    .then(
      (res) => {
        return res.body;
      },
      (err) => {
        // TODO
      }
    );
};
const fetchDataPost = async (url: string, token: string, data: any) => {
  return superagent
    .post(url)
    .set("Authorization", `Bearer ${token}`)
    .send(data)
    .set("Accept", "application/json")
    .then(
      (res) => {
        return res.body;
      },
      (err) => {
        // TODO
      }
    );
};

const fetchDataPut = async (url: string, token: string, data: any) => {
  return superagent
    .put(url)
    .set("Authorization", `Bearer ${token}`)
    .send(data)
    .set("Accept", "application/json")
    .then(
      (res) => {
        return res.body;
      },
      (err) => {}
    );
};

export const getProjectsData = async (token) => {
  return fetchDataGet(PROJECTS_URL, token);
};

export const getApprovalStatuses = async (token) => {
  const approvalStatuses = await fetchDataGet(APPROVAL_STATUSES_URL, token);
  return approvalStatuses;
};

export const getDuplicateIds = async (token) => {
  // duplicates check returns an array of objects. Each object has a 'baseProject' and a
  // 1-length array of "potentials".

  // potentials has six flags that can position them as a duplicate:
  // "country"
  // "zipCode"
  // "claimantEmail"
  // "installDate"
  // "nameplateCapacity"
  // "walletAddress"
  //
  // We disregard the walletAddress. Out of the other five, if three are
  // true, the project should be flagged as a duplicate.

  // TODO: write interface defs and rewrite to use node-granting

  return fetchDataGet(DUPLICATES_URL, token);
};

export const getDuplicatesDataForProject = async (
  projectId: string,
  token: string
) => {
  // TODO: write interface defs

  return fetchDataGet(DUPLICATES_URL + "/" + projectId, token);
};

export const submitProjects = async (
  projectsData: Project[],
  token: string
) => {
  const flattenedApprovalProjects = projectsData.map((project: Project) => {
    const approvalStatus = project.approvalStatus;
    (project.approvalStatus as any) = approvalStatus.code
    return project
  })
 return fetchDataPost(APPROVE_URL, token, flattenedApprovalProjects)
}

export const setProjectApprovalStatus = async (
  project: Project,
  approvalStatus: ApprovalStatus,
  token: string,
) => {
  
  return fetchDataPost(`${APPROVE_PROJECT_URL}/${project.id}`, token, {approvalStatusCode: approvalStatus.code})
}

export const setWalletAddress = async (
  projectId: string,
  walletAddress: string,
  token: string,
) => {
  
  return fetchDataPost(`${SET_WALLET_URL}/${projectId}`, token, {walletAddress: walletAddress})
}

export const getAdjustmentGrantsData = async (token: string) => {
  return fetchDataGet(ADJUSTMENT_GRANTS_URL, token);
};

export const getGeneratedGrantsData = async (token: string) => {
  return fetchDataGet(GENERATED_GRANTS_URL, token);
};

export const getPeriodicGrantsData = async (
  grantPeriod: { grantMonth: number; grantYear: number },
  token: string
) => {
  return fetchDataPost(PERIODIC_GRANTS_URL, token, grantPeriod);
};

export const getRedemptionGrants = async (token: string) => {
  return await fetchDataGet(REDEMPTION_GRANTS_URL, token)
}

export const getRegistrationsData = async (token: string) => {
  return fetchDataGet(REGISTRATIONS_URL, token);
};

export const submitRegistrationsData = async (
  registrationsData: Registration[],
  token: string
) => {
  return fetchDataPut(REGISTRATIONS_URL, token, registrationsData);
};

export const submitPaymentsCSV = async (
  token: string,
  CSVFile: any
) => {
  return fetchDataPost(UPLOAD_CSV_URL, token, CSVFile);
};

export const getProjectDetails = async (
  token: string,
  projectId: string
) => {
  return fetchDataGet(`${PROJECT_DATA_URL}/${projectId}`, token);
};

export const updateProject = async(
  token: string,
  updateData: Partial<Project>
) => {
  return fetchDataPut(`${UPDATE_PROJECT_URL}`, token, updateData)
}

export const searchAllProjects = async (
  token: string,
  searchTerm: object
) => {
  console.log({url: `${PROJECT_URL}/search`, token, searchTerm})
  return fetchDataPost(`${PROJECT_URL}/search`, token, searchTerm);
};

export const getDupesForProject = async (
  token: string,
  projectId: string
) => {
  return fetchDataGet(`${PROJECT_URL}/${projectId}/duplicates`, token);
};

export const getProjectChangelog = async (
  token: string,
  projectId: string
) => {
  return fetchDataGet(`${PROJECT_URL}/${projectId}/changelog`, token);
};