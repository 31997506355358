import React from 'react';
import {
  TableContainer,
  Typography,
  Paper,
} from '@material-ui/core'
import MUIDataTable, {Options} from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';

import { getTableTitle, formatProjectsForTable, getTableHeaders, getMuiTheme } from './TableUtilities';
import { ProjectViewStyle, TableType } from './Enums';
import { HeadCell } from './Interfaces';
import { LoadingPlaceholder } from './LoadingPlaceholder';

export default function SearchableProjectsTable({
  inputData,
  changeDisplayType,
  updateActiveProjectId,
  toggleShowModal,
  tableType,
  showConfirmModal,
  approvalStatuses,
  rejectFromDuplicateModal,
}) {
  const rejectButtonAction = (event: any, projectId: string) => {
    const isBaseProject = tableType === TableType.BASE_PROJECT_VIEW;
    return rejectFromDuplicateModal(event.target.value, projectId, isBaseProject)
  }

  let data;

  if (tableType === TableType.DUPLICATES_VIEW || tableType === TableType.BASE_PROJECT_VIEW) {
    data = formatProjectsForTable(inputData, tableType, approvalStatuses, rejectButtonAction);
  } else {
    data = formatProjectsForTable(inputData);
  }

  const tableHeader = getTableTitle(tableType);

  const headCells: HeadCell[] = getTableHeaders(tableType);

  const handleRowClick = (row) => {
    updateActiveProjectId(row[0])
    changeDisplayType(ProjectViewStyle.Dashboard)
  }

  const handleRowClickFromSearch = (row) => {
    changeDisplayType(ProjectViewStyle.Dashboard, {
      displayType: ProjectViewStyle.Dashboard,
      activeProjectId: row[0],
      projectsData: inputData,
      fromSearch: true,
    })
  }

  const handleRowClickFromDuplicateModal = (row) => {
    changeDisplayType(ProjectViewStyle.Dashboard, {
      displayType: ProjectViewStyle.Dashboard,
      activeProjectId: row[0],
      projectsData: inputData,
      fromSearch: true,
    });
    toggleShowModal()
  }

  let options: Options = {
    filterType: 'checkbox',
    search: true,
    print: false,
    selectableRows: 'none',
  };

  if (tableType === TableType.PROJECTS_VIEW) {
    options = {
      ...options,
      download: false,
      onRowClick: handleRowClick,
    }
  } else if (tableType === TableType.ALL_PROJECTS) {
    options = {
      ...options,
      download: true,
      searchOpen: true,
      onRowClick: handleRowClickFromSearch,
      downloadOptions: {
        filename: `project_search_${Date.now()}`
      }
    }
  } else if (tableType === TableType.BASE_PROJECT_VIEW || tableType === TableType.DUPLICATES_VIEW) {
    options = {
      ...options,
      download: false,
      onRowClick: handleRowClickFromDuplicateModal,
    }
  }


  return (
    data[0] == null ? <> <LoadingPlaceholder /> </> :
    <MuiThemeProvider theme={getMuiTheme()}>
    <div className="projectsTable">
      <Paper className="paper">
        <TableContainer>
        <MUIDataTable
          title={
            <Typography className="tableTitle" variant="h4" id="tableTitle" component="div">
              {tableHeader}
            </Typography>
          }
          data={data}
          columns={headCells}
          options={options}
        />
        <div className="row-count">
            Count: {data.length}
        </div>
        </TableContainer>
      </Paper>
    </div>
    </MuiThemeProvider>
  );
}
