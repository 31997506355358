import * as React from "react"

import { RegistrationView } from "./RegistrationView"
import { getRegistrationsData } from "./FetchUtilities";
import { approvalCodes } from "./utils";

export interface Props {
  token: string
}

/**
 * Controller and aggregator class for the AdjustmentGrants view
 * components, handles fetch and data flow
 */
const RegistrationContainer = (props: Props) => {
  const [registrationsData, updateRegistrationsData] = React.useState([]);
  const [approvalStatuses, updateApprovalStatuses] = React.useState(null);

  React.useEffect(() => {
    const getData = async () => {
      const registrationsData = await getRegistrationsData(props.token);
      const approvalStatuses = approvalCodes;

      updateRegistrationsData(registrationsData);
      updateApprovalStatuses(approvalStatuses);
    }
    getData();
  }, [props.token])

  return (
    <RegistrationView
      registrationData = {registrationsData}
      approvalStatuses = {approvalStatuses}
    />
  )
}

export default RegistrationContainer;