import * as React from "react"

import {
  getRegistrationsData,
  getDuplicateIds,
} from "./FetchUtilities"
import { ReportingView } from "./ReportingView"
import {Project} from "./Interfaces";
import { ReportTypes } from "./Enums"

interface Props {
  token: string
}

interface State {
  allRegistrationsReportData: any
  isError: boolean
 }

/**
 * Controller and aggregator class for the Reporting view
 * components, handles fetch and data flow
 */
export class ReportingContainer extends React.Component<Props, State> {

  constructor(props: Props ) {
    super(props)
    this.state = {
      allRegistrationsReportData: null,
      isError: false,
      }
  }

  // In order to keep load times semi-reasonable, only build reports on click.
  // TODO: should have some error or handling if there's a reportType passed from the child
  // to this function that doesn't exist.
  // TODO: Is it better to pass one massive reportData object and check for the keys' existence? Probably less crazy
  // than rewriting the props for the child function every time we add a report.
  buildReport(reportType: number) {
    switch(reportType) {
      case ReportTypes.AllRegistrationsReport:
        this.buildAllRegistrationsReport()
        break
    }
  }

  buildAllRegistrationsReport() {
    Promise.all([getRegistrationsData(this.props.token), getDuplicateIds(this.props.token)]).then((registrationsAndDuplicatesAndStatuses: any) => {
      const registrationData = registrationsAndDuplicatesAndStatuses[0]
      const duplicatesData = registrationsAndDuplicatesAndStatuses[1]

      const reportData = registrationData.map((registration: Project) => {

        let duplicateIndicator = "false";
        if (duplicatesData != null) {
          duplicateIndicator = duplicatesData.indexOf(registration.id) >= 0 ? "true" : "false"
        }

        return {
          id: registration.id,
          email: registration.claimant,
          firstName: registration.firstName,
          lastName: registration.lastName,
          address: registration.address,
          address2: registration.address2,
          city: registration.city,
          zipCode: registration.zipCode,
          country: registration.country,
          nameplate: registration.nameplate,
          installDate: registration.installDate,
          documentationLink: registration.documentation,
          claimantWalletAddress: registration.walletAddress,
          // referralEmail: registration.referralEmail,
          // referralWallet: registration.referralAddress,
          approvalStatus: registration.approvalStatusCode,
          actualKwh: 0,
          duplicateIndicator,
        }
      })

      this.setState({
        ...this.state,
        allRegistrationsReportData:reportData,
      })
    })
  }

  render() {
    return(
      <ReportingView
        allRegistrationsReportData = {this.state.allRegistrationsReportData}
        buildReport = {this.buildReport.bind(this)}
      />
    )
  }
}