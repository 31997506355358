import * as React from "react"
import * as _ from "lodash"

import { PDFComponent } from "./PDFComponent"
import { ImageComponent } from "./ImageComponent"
import { UnknownDocumentComponent } from "./UnknownDocumentComponent"

interface Props {
  fileUrl: string
}

// Detect and display documents
export const DocumentPanel = (props: Props) => {

  const {
    fileUrl
  } = props

  const IMAGE_TYPES = ["png", "jpg", "jpeg", "gif"]

  const fileExtension = fileUrl.split('.').pop().toLowerCase()

  let renderComponent

  if(_.indexOf(IMAGE_TYPES, fileExtension) >= 0) {
    renderComponent =
      <ImageComponent
        fileUrl={fileUrl}
      />
  }

  else if(fileExtension === "pdf") {
    renderComponent =
      <PDFComponent
        fileUrl={fileUrl}
      />
  }

  else {
    renderComponent =
      <UnknownDocumentComponent
        fileUrl={fileUrl}
      />
  }

  return(
    <div className="document-panel-container">
      <div className="panel-header">Documentation</div>
      <div className="document-panel-document">
        {renderComponent}
      </div>
    </div>
  )
}