import * as React from "react"
import styled from 'styled-components'

import GrantsTable from "./GrantsTable";
import { TableType } from "./Enums"
import { LoadingPlaceholder } from "./LoadingPlaceholder";
import { IGeneratedGrant } from "./Interfaces";

interface Props {
  generatedGrantsData: IGeneratedGrant[]
}

const GeneratedGrantsViewContainer = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: space-around;
  padding: 2rem;
  font-size: 12px;
  flex-flow: column;
`

export const GeneratedGrantsView = (props: Props) => {

  const {
    generatedGrantsData,
  } = props

  const bountyGrants = generatedGrantsData.filter((grant) => {
    return ((grant.typeTag === 'ABGRT' || grant.typeTag === 'BGRT'))
  })

  const ndfGrants = generatedGrantsData.filter((grant) => {
    return ((grant.typeTag === 'ANDF' || grant.typeTag === 'NDF'))
  })

  const adjustmentAndPeriodicGrants = generatedGrantsData.filter((grant) => {
    return (!(grant.typeTag === 'ABGRT' || grant.typeTag === 'BGRT' || grant.typeTag === 'ANDF' || grant.typeTag === 'NDF'))
  })


  return(
    adjustmentAndPeriodicGrants[0] == null ? <LoadingPlaceholder /> :

    <GeneratedGrantsViewContainer>
      <GrantsTable
      inputData={adjustmentAndPeriodicGrants}
      tableType={TableType.GENERATED_GRANTS}
      />
      <GrantsTable
      inputData={ndfGrants}
      tableType={TableType.NDF_GRANTS}
      />
      <GrantsTable
      inputData={bountyGrants}
      tableType={TableType.BOUNTY_GRANTS}
      />      
    </GeneratedGrantsViewContainer>
  )
}
