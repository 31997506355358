import * as React from "react"
import * as _ from "lodash"
import moment from "moment"

import { Project } from "./Interfaces"
import { DuplicateMap } from "./DuplicateMap"

interface Props {
  project: Project
  showDuplicatesModal: (projectId: string) => void
  duplicateMap: DuplicateMap,
  hasDuplicate: boolean,
}

// Detect and display documents
export const AlertsPanel = (props: Props) => {

  const {
    project,
    showDuplicatesModal
  } = props


  const NAMEPLATE_WARNING = 1000;
  const FREE_EMAILS = ["gmail.com", "yahoo.com"]

  const alerts = []

  console.log(project)

  // Check if nameplate capacity is high
  if(project.nameplate >= NAMEPLATE_WARNING) {
    alerts.push("Nameplate capacity > " + NAMEPLATE_WARNING)
  }

  // Check if user's email address is in a list of free emails
  if(_.indexOf(FREE_EMAILS, (project.claimant.email).split('@').pop().toLowerCase()) >= 0) {
    alerts.push("Free email provider used")
  }

  // Check if the project ID is in the duplicates ID list
  if(props.hasDuplicate) {
    // wants href to go somewhere, but we don't need it to here
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    alerts.push(<a href="#" onClick={() => showDuplicatesModal(project.id)} className="dupalert">Potential duplicate project</a>)
  }

  // Check if actual granting kWh is more than +/- 10% of estimated
  if(project.actualClaim === true) {

    const MAX_LOOKBACK = moment('01/01/2010')
    const CLAIM_DATE = moment(project.createdAt)
    const INSTALL_DATE = moment(project.installDate)
    const NAMEPLATE_ESTIMATED_PERCENT = .15

    // determine days eligible for granting
    let eligibleDays = 0

    if(INSTALL_DATE.isBefore(MAX_LOOKBACK)) {
      eligibleDays = CLAIM_DATE.diff(MAX_LOOKBACK, 'days')
    }
    else {
      eligibleDays = CLAIM_DATE.diff(project.installDate, 'days')
    }

    // calculate estimate for comparison baseline
    const estimatedGrantAmount = eligibleDays * project.nameplate * 24 * NAMEPLATE_ESTIMATED_PERCENT / 1000

    // if the install date was before the lookback, estimate the kWh generated per day and multiply
    // by the eligible days

    let eligibleKwh = project.kwhAtClaimingDate

    if(INSTALL_DATE.isBefore(MAX_LOOKBACK)) {
      const totalDays = CLAIM_DATE.diff(INSTALL_DATE, 'days')
      const avgKwhPerDay = project.kwhAtClaimingDate / totalDays
      eligibleKwh = avgKwhPerDay * eligibleDays
    }

    const actualGrantAmount= eligibleKwh / 1000
    const variance = Math.abs((estimatedGrantAmount - actualGrantAmount) / estimatedGrantAmount)

    if(variance > .1) {
      alerts.push("Large variance between estimated SLR (" + estimatedGrantAmount + ") and actual claim SLR (" + actualGrantAmount + ")")
    }

  }

  return(
    <div className="alerts-panel">
      <div className="panel-header">Alerts</div>
      <div className="alerts-panel-alerts">
        {alerts.map((alertMessage: string) => {
          return (
            <div className="alerts-panel-alert" key={alertMessage}>
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
              {alertMessage}
            </div>
          )
        })}
      </div>
    </div>
  )
}