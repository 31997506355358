import styled from "styled-components";

export const Container = styled.section`
  // background-color: #ddd;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const StyledP = styled.p`
  font-size: 1.5rem;
`;

export const Wrapper = styled.section`
  align-items: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`;

export const Button = styled.button`
  background-color: rgb(81, 93, 160);
  border-radius: 10px;
  color: #fff;
  font-size: 1.8rem;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 10px 20px;

  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
  }

  &:disabled {
    background-color: rgb(153, 153, 153);
    cursor: not-allowed;
  }
`;

export const Error = styled.p`
  color: red;
  font-size: 1.7rem;
  margin-top: 10px;
`;