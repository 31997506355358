import * as React from "react"

import { LoadingPlaceholder } from "./LoadingPlaceholder";
import SearchableProjectsTable from "./SearchableProjectsTable";
import { ApprovalStatus, ApprovalStatusContainer, Project } from "./Interfaces";
import { ProjectViewStyle, ModalTypes, TableType } from "./Enums";
import { ProjectDataViewContainer } from "./ProjectDataView";

interface Props {
  projectsData: any
  approvalStatuses: ApprovalStatusContainer
  showDuplicatesModal: (baseProjectId: string) => void
  shouldShowModal: boolean
  modalComponent: any
  changeDisplayType: (displayType: ProjectViewStyle) => void
  updateActiveProjectId: (id: string) => void
  toggleShowModal: (modalType?: ModalTypes) => void
  rejectFromDuplicateModal: (value: string, projectId: String, isBaseProject: boolean) => void,
  showConfirmModal: (approvalStatus: ApprovalStatus, project: Project) => void
}

export const ProjectsTableView = (props: Props) => {

  const {
    projectsData,
    changeDisplayType,
    updateActiveProjectId,
  } = props

  return (
    <div className="projects-view-container">      
        <div className="view-switch">
          <div className="view-switch-button" onClick={() => {
            updateActiveProjectId(props.projectsData[0].id)
            changeDisplayType(ProjectViewStyle.Dashboard)
          }}>
            Switch to single-project layout
          </div>
        </div>
        <div className="projects-view-header">
        </div>
        {projectsData ?
          <div className="projects-view-grid">
            <ProjectDataViewContainer>
            <SearchableProjectsTable
              inputData={props.projectsData}
              updateActiveProjectId={props.updateActiveProjectId}
              changeDisplayType={props.changeDisplayType}
              toggleShowModal = {props.toggleShowModal}
              tableType={TableType.PROJECTS_VIEW}
              showConfirmModal={props.showConfirmModal}
              approvalStatuses={props.approvalStatuses}
              rejectFromDuplicateModal={()=>({})}
            />
          </ProjectDataViewContainer>
        </div>
        :
        <LoadingPlaceholder />
      }     
    </div>
  );
}

