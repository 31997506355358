import React from 'react';
import {
  TableContainer,
  Typography,
  Paper,
  MuiThemeProvider,
} from '@material-ui/core'
import MUIDataTable, {Options} from "mui-datatables";

import { getTableTitle, getTableHeaders, getMuiTheme } from './TableUtilities';
import { HeadCell } from './Interfaces';
import { TableType } from './Enums';

export default function GrantsTable({
  inputData,
  tableType,
}) {
 
  const tableHeader = getTableTitle(tableType);

 
  const headCells: HeadCell[] = getTableHeaders(tableType);

  let downloadOptions = {};

  if(tableType === TableType.GENERATED_GRANTS) {
    downloadOptions = {
      ...downloadOptions,
      filename: `gen_grant_${Date.now()}`
    }
  }

  if(tableType === TableType.NDF_GRANTS) {
    downloadOptions = {
      ...downloadOptions,
      filename: `ndf_grant_${Date.now()}`
    }
  }

  if(tableType === TableType.BOUNTY_GRANTS) {
    downloadOptions = {
      ...downloadOptions,
      filename: `bounty_grant_${Date.now()}`
    }
  }

  if(tableType === TableType.PERIODIC_GRANTS) {
    downloadOptions = {
      ...downloadOptions,
      filename: `periodic_grant_${Date.now()}`
    }
  }

  if(tableType === TableType.REDEMPTION_GRANTS) {
    downloadOptions = {
      ...downloadOptions,
      filename: `redemption_grant_${Date.now()}`
    }
  }

  let options: Options = {
    filterType: 'checkbox',
    search: true,
    print: false,
    selectableRows: 'none',
    download: true,
    downloadOptions,
  };
  
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
    <div className="grantsTable">
      <Paper className="paper">
      <TableContainer>
        <MUIDataTable
          title={
            <Typography className="tableTitle" variant="h4" id="tableTitle" component="div">
              {tableHeader}
            </Typography>
          }
          data={inputData}
          columns={headCells}
          options={options}
        />
        <div className="row-count">
            Count: {inputData.length}
        </div>
        </TableContainer>
      </Paper>
    </div>
    </MuiThemeProvider>
  );
}
