import React, { useState } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { Button } from "@material-ui/core";

import { Container, StyledP, Wrapper, Error } from "./styles";
import { submitPaymentsCSV } from "../FetchUtilities";

const UploadPaymentsContainer: React.FC = () => {
  const [state, setState] = useState({
    file: null,
    error: false,
    loading: false,
    success: false,
    errorMessage: "",
    isRow: false,
    row: {
      GUID: "",
      Project_id: "",
      amount: "",
      createdAt: "",
      grantDate: "",
      transactionId: "",
      typeTag: "",
      walletAddress: "",
    },
  });

  const { getTokenSilently } = useAuth0();

  function handleOnChange(event: any) {
    setState({
      ...state,
      file: event.target.files[0],
      loading: false,
      success: false,
      error: false,
      isRow: false,
      errorMessage: "",
    });
  }

  async function handleUploadPayment() {
    setState({
      ...state,
      success: false,
      error: false,
      isRow: false,
      errorMessage: "",
      loading: true,
    });

    const jwtToken = await getTokenSilently();
    const data = new FormData();
    data.append("file", state.file);
    const response = await submitPaymentsCSV(jwtToken, data);

    // handle errors globally?!
    if (!response) {
      setState({
        ...state,
        loading: false,
        error: true,
        success: false,
        errorMessage: 'Request failed'
      });

      return;
    }

    if (response.data) {
      setState({
        ...state,
        error: false,
        success: true,
        loading: false,
      });
    } else if (response.errorData) {
      setState({
        ...state,
        error: true,
        success: false,
        errorMessage: response.errorData.message,
        isRow: response.errorData.row ? true : false,
        row: response.errorData.row ? response.errorData.row : {},
        loading: false,
      });
    }
  }

  return (
    <Container>
      <Wrapper>
        <input
          type="file"
          name="file"
          accept=".csv"
          onChange={handleOnChange}
        />
        <br />
        <Button
          variant="contained"
          id="defaultButton"
          size="large"
          disabled={!state.file}
          className="btn"
          onClick={handleUploadPayment}
        >
          Upload Payments
        </Button>

        {state.loading && <StyledP>loading...</StyledP>}

        {state.success && <StyledP>file uploaded!</StyledP>}

        {state.error && (
          <div>
            <Error>{state.errorMessage}</Error>
          </div>
        )}

        {state.isRow && (
          <div>
            <h2>state.ROW: </h2>
            <StyledP>GUID - {state.row.GUID}</StyledP>
            <StyledP>Project_id - {state.row.Project_id} </StyledP>
            <StyledP>amount - {state.row.amount} </StyledP>
            <StyledP>createdAt - {state.row.createdAt} </StyledP>
            <StyledP>grantDate - {state.row.grantDate} </StyledP>
            <StyledP>transactionId - {state.row.transactionId} </StyledP>
            <StyledP>typeTag - {state.row.typeTag} </StyledP>
            <StyledP>walletAddress - {state.row.walletAddress} </StyledP>

            <Error>Please fix it and try submiting again</Error>
          </div>
        )}
      </Wrapper>
    </Container>
  );
};

export default UploadPaymentsContainer;
