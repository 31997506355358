import * as React from "react"
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"

interface Props {
 geocodedLocation: {
    lat: number,
    lng: number
  }
}

// Handles the HOC wrangling to deal with the changing geocoded location
export const MapComponent = (props: Props) => {

  const {
    geocodedLocation
  } = props

  const isMarkerShown = true
  
  const RenderMapComponent = withGoogleMap((props) =>
  {
    return <GoogleMap
      defaultZoom={20}
      defaultMapTypeId={google.maps.MapTypeId.HYBRID}
      defaultCenter={geocodedLocation}
    >
      {isMarkerShown && <Marker position={geocodedLocation} />}
    </GoogleMap>  
  }
  )

  return(
    <RenderMapComponent     
      containerElement={<div style={{ height: `400px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  )
}