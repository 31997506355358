import { Button, Input } from "@material-ui/core";
import * as React from "react"
import styled from 'styled-components'

import { TableType, ApplicationStates } from "./Enums"
import SearchableProjectsTable from "./SearchableProjectsTable";

interface Props {
  projectsData: any
  fetchProjectData: (key: string, value: string) => any
  changeDisplayPage: (page: ApplicationStates) => void
  setExtraProps: (obj: any) => void,
}

export const ProjectDataViewContainer = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: flex-start;
  padding: 2rem;
  font-size: 12px;
  flex-flow: column;
`

export const ProjectDataView = (props: Props) => {
  const [searchTerm, updateSearchTerm] = React.useState("");

  const [category, setCategory] = React.useState('id');

  const handleCategoryChange = (category) => {
   setCategory(category);
  }

  const {
    projectsData,
    fetchProjectData,
    changeDisplayPage,
    setExtraProps,
  } = props

  const changeToDashboard = (displayType: ApplicationStates, obj: any) => {
    setExtraProps(obj);
    changeDisplayPage(displayType);
  }

  const handleOnChange = (event) => {
    updateSearchTerm(event.target.value)
  }

  return (
    <>
    { projectsData == null ? <ProjectDataViewContainer>
      <div className="searchBarContainer">
       <Input className="searchBar"
        onChange={handleOnChange}
        placeholder={"Enter your search term"}
      />
      <select name="category" value={category} onChange={event => handleCategoryChange(event.target.value)}>
        <option id="0" >id</option>
        <option id="1" >Claimant_email</option>
        <option id="2" >claimId</option>
      </select>

      <div className="searchButtonContainer">
      <Button variant="contained" id="searchButton" size="large" onClick={() => fetchProjectData(category, searchTerm)}>
        Search
      </Button>
      </div>
      <br></br>
      <br></br>
      <p>No results! Please try a different search term</p>
      </div>
    </ProjectDataViewContainer> :
    projectsData[0] != null ?
    <ProjectDataViewContainer>
      <SearchableProjectsTable
        inputData={projectsData}
        updateActiveProjectId={()=> {}}
        changeDisplayType={changeToDashboard}
        toggleShowModal = {() => {}}
        tableType={TableType.ALL_PROJECTS}
        showConfirmModal={()=>({})}
        approvalStatuses={({})}
        rejectFromDuplicateModal={()=>({})}
      />
    </ProjectDataViewContainer> :
    <ProjectDataViewContainer>
      <div className="searchBarContainer">
       <Input className="searchBar"
        onChange={handleOnChange}
        placeholder={"Enter your search term"}
      />

      <div className="searchButtonContainer">
      <select name="category" value={category} onChange={event => handleCategoryChange(event.target.value)}>
        <option id="0" >id</option>
        <option id="1" >Claimant_email</option>
        <option id="2" >claimId</option>
      </select>
      <Button variant="contained" id="searchButton" size="large" onClick={() => fetchProjectData(category, searchTerm)}>
        Search
      </Button>
      </div>

      </div>
    </ProjectDataViewContainer>}
    </>
  )
}
