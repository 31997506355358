import * as React from "react"
import  moment from "moment"
import styled from 'styled-components'

import GrantsTable from "./GrantsTable";
import { TableType } from "./Enums"
import { LoadingPlaceholder } from "./LoadingPlaceholder";

interface Props {
  periodicGrantsData: any
  currentDate: {grantMonth: number, grantYear: number}
  switchDate: (newMonth: number, newYear: number) => void
}

const PeriodicGrantsViewContainer = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: space-around;
  padding: 2rem;
  font-size: 12px;
  flex-flow: column;
`

export const PeriodicGrantsView = (props: Props) => {

  const {
    periodicGrantsData,
    currentDate,
    switchDate,
  } = props

  // Update on value change for dropdowns
  const handleMonthSelect = (e: any) => {
    switchDate(e.target.value, currentDate.grantYear)
  }

  const handleYearSelect = (e: any) => {
    switchDate(currentDate.grantMonth, e.target.value)
  }
  const months = [1,2,3,4,5,6,7,8,9,10,11,12]
  const years = [2015, 2016, 2017, 2018, 2019, 2020]

  // eslint-disable-next-line
  const isSelectedGrantPeriodInTheFuture = () => {
    // moment months are 0-indexed
    const thisMonth = moment().month() + 1
    const thisYear = moment().year()

    if(currentDate.grantYear > thisYear) {
      return true
    }
    else if(currentDate.grantYear === thisYear && currentDate.grantMonth > thisMonth) {
      return true
    }
    return false
  }

  return(
    periodicGrantsData[0] == null ? <LoadingPlaceholder /> :
      
    <div className="grantsTable">
      <PeriodicGrantsViewContainer>
      <div className = "date-select-container">
          Grant Date:
          <select value={currentDate.grantMonth} className="periodic-grants-month-select" onChange={handleMonthSelect}>
            {months.map((month: number) => {
              return <option value={month} key={month}>{month}</option>
            })}
          </select>
          /
          <select value={currentDate.grantYear} className="periodic-grants-year-select" onChange={handleYearSelect}>
            {years.map((year: number) => {
              return <option value={year} key={year}>{year}</option>
            })}
          </select>
        
      </div>
      <br></br>
        <GrantsTable
          inputData={periodicGrantsData}
          tableType={TableType.PERIODIC_GRANTS}
        />
      </PeriodicGrantsViewContainer>
    </div>
  )
}