import React from "react"
import styled from "styled-components";

const LoginContainerDiv = styled.div`
  display: flex;
  flex: 0 1 50%;
  background-color: #333;
  margin: auto;
  flex-flow: column;
  height: 50%;
  padding: 4rem;
`
const LoginContainerTitle = styled.div`
  display: flex;
  flex: 0 1 100%;
  color: #ffcd43;
  justify-content: space-around;
  font-size: 4rem;
  align-items: center;
`

interface IProps {
  children?: React.ReactNode
}
/**
 * Login container
 */
export const LoginContainer: React.FC = (props: IProps) => {

  return(
    <LoginContainerDiv>
      <LoginContainerTitle>SolarFusion Login</LoginContainerTitle>
      {props.children}
    </LoginContainerDiv>
  )
}

