import React, {useState, useEffect} from "react"

import { GeneratedGrantsView } from "./GeneratedGrantsView"
import { getGeneratedGrantsData } from "./FetchUtilities"
import { useAuth0 } from "../react-auth0-spa"
import { removeNonAsciiChars } from "./utils"
import { IGeneratedGrant } from "./Interfaces"

/**
 * Controller and aggregator class for the AdjustmentGrants view
 * components, handles fetch and data flow
 */
export const GeneratedGrantsContainer: React.FC = () => {

  const [isError, setIsError] = useState(false)
  const [generatedGrantsData, setGeneratedGrantsData] = useState([] as IGeneratedGrant[])
  const { getTokenSilently } = useAuth0()

  useEffect(() => {
    async function fetchGeneratedGrantsData() {
      const jwtToken = await getTokenSilently()
      const result = await getGeneratedGrantsData(jwtToken);
      console.log(result)
      if(!result && isError === false) {
        setIsError(true)
      }

      else if (result[0] != null) {
        setGeneratedGrantsData(result);
      }
    }
    fetchGeneratedGrantsData()
  }, [getTokenSilently, isError]);


  const formatGeneratedGrants = (generatedGrantsData:IGeneratedGrant[]) => {
    if (generatedGrantsData == null || generatedGrantsData.length === 0) {
      return []
    }
    return generatedGrantsData
      .map((generatedGrant: IGeneratedGrant) => {
        // round to 2 decimal places
        generatedGrant.amount = Math.max(parseFloat(generatedGrant.amount), 0.01).toFixed(2)

        generatedGrant.guid = removeNonAsciiChars(generatedGrant.guid)
        return generatedGrant
      })
  }


  return(
    <GeneratedGrantsView
      generatedGrantsData = {formatGeneratedGrants((generatedGrantsData))}
    />
  )

}
