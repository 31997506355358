import _ from "lodash";

export const approvalCodes = {
  approvalCodes: [
      {
          code: "A",
          title: "Approved",
          details: "Approved Project"
      }
  ],
  rejectionCodes: [
      {
          code: "D",
          title: "Deactivated",
          details: "Offline Project"
      },
      {
          code: "P",
          title: "Pending",
          details: "Pending Approval"
      },
      {
          code: "R1",
          title: "Rej1 Missing Doc",
          details: "Missing Documentation"
      },
      {
          code: "R2",
          title: "Rej2 Insuff Doc",
          details: "Insufficient/Not Official Documentation"
      },
      {
          code: "R3",
          title: "Rej3 Not a claim",
          details: "Not a claim for solar electricity"
      },
      {
          code: "R4",
          title: "Rej4 Incorrect field",
          details: "Mandatory field not entered correctly"
      },
      {
          code: "R5",
          title: "Rej5 duplicate",
          details: "Duplicate Claim"
      },
  ]
}

export const getApprovalStatusTitleByCode = (code: string) => {
  const codeToCheck = code.toUpperCase();
  if (codeToCheck === "A") {
    return approvalCodes.approvalCodes[0].title;
  }
  const title = _.find(approvalCodes.rejectionCodes, (each) => each.code === codeToCheck);
  if(title != null) {
    return title.title;
  }
  return "unknown code"
};

export const getApprovalStatusByTitle = (title: string) => {
  if (title === "Approved") {
    return approvalCodes.approvalCodes[0];
  }
  return _.find(approvalCodes.rejectionCodes, (each) => each.title === title);
};

export const removeNonAsciiChars = (input: string) => {
  // eslint-disable-next-line no-control-regex
  return input.replace(/[^\x00-\x7F]/g, "?");
}