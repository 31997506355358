import * as React from "react"

interface Props {
 fileUrl: string
}

export const UnknownDocumentComponent = (props: Props) => {

  const {
    fileUrl
  } = props

  return(
    <div className="document-list">
      <div className="document-alert-banner">
        The filetype could not be determined. Be careful.
      </div>
      <div className="document-list">
        <a href={fileUrl} target="_blank" rel="noopener noreferrer"><i className="fa fa-file-o document-icon"></i><span>{ fileUrl.split('/').pop()}</span></a>
      </div>
    </div>
  )
}