import * as React from "react"
import MUIDataTable, {Options} from "mui-datatables";

import { MuiThemeProvider, Paper, TableContainer } from "@material-ui/core"
import { getMuiTheme } from "./TableUtilities"

interface Props {
  changelog: any
}

// Detect and display documents
export const ProjectChangelogPanel = (props: Props) => {

  const {
    changelog
  } = props;

  let sortedChangelog = changelog;

  if(sortedChangelog != null) {
    if (sortedChangelog[0] != null) {
      sortedChangelog = sortedChangelog.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
    }
  }

  const options: Options = {
    filterType: 'checkbox',
    search: false,
    print: false,
    selectableRows: 'none',
    download: false,
    pagination: false,
    filter: false,
    viewColumns: false,
  };


  const headCells = [
    { id: 'username', name: 'username', label: 'User name' },
    { id: 'userEmail', name: 'userEmail', label: 'User email' },
    { id: 'changes', name: 'changes', label: 'Changes' },
    { id: 'createdAt', name: 'createdAt', label: 'Created at' },
  ];

  return(
    changelog == null ? <> <div /> </> :
    <div className="document-panel-container">
      <div className="panel-header">Project Changelog</div>
      <div className="document-panel-document">

        <MuiThemeProvider theme={getMuiTheme()}>
          <div className="changelogTable">
            <Paper className="paper">
              <TableContainer>
                <MUIDataTable
                  data={changelog}s
                  columns={headCells}
                  options={options}
                />
              </TableContainer>
            </Paper>
          </div>
        </MuiThemeProvider>
      </div>
    </div>
  )
}