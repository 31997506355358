import * as React from "react"

/**
 * Show a loading animation
 */

export const LoadingPlaceholder = () => {

  const grayscaleBG = ["#333, #333, #333, #333, #333"]
  let backgrounds = []

  backgrounds = grayscaleBG


  return(
    <div className="loading-placeholder-container">
      <div className="spinner">
        <div className="rect1" style={{backgroundColor: backgrounds[0]}}></div>
        <div className="rect2" style={{backgroundColor: backgrounds[1]}}></div>
        <div className="rect3" style={{backgroundColor: backgrounds[2]}}></div>
        <div className="rect4" style={{backgroundColor: backgrounds[3]}}></div>
        <div className="rect5" style={{backgroundColor: backgrounds[4]}}></div>

      </div>
    </div>
  )
}