import * as React from "react"

import { MapComponent } from "./MapComponent"

interface Props {
  address: string
  isEditMode: boolean
}

// TODO: update map on new project
// TOOD: update map on address change

interface State {
  geocodedLocation: {
    lat: number,
    lng: number
  }
  isMapError: boolean
}

export class MapPanel extends React.Component<Props, State> {

  constructor(props: Props ) {
    super(props)
    this.state = {
      geocodedLocation: null,
      isMapError: false
    }
  }

  componentDidMount() {
    this.geocodeAddress(this.props.address)
  }

  componentWillReceiveProps(nextProps: Props) {
    // don't update until the user finishes saving
    // re-geocode on save. because the temporary edit address is passed in props,
    // we either have to re-geocode on every change (which is every character typed in the address field)
    // or do it once on save, even if nothing changed, which is a smaller performance hit.
    if(
      (this.props.isEditMode === true && nextProps.isEditMode === false) ||
      (this.props.isEditMode === false && nextProps.isEditMode === false)
    )
    {
      this.geocodeAddress(nextProps.address)
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    // don't update the map if the address is changing in edit mode
    if(nextProps.isEditMode === true) {
      return false
    }
    return true;
  }

  // Uses the Google geocoder and returns lat/lng for the map
  geocodeAddress(address: string) {
    let geocoder = new google.maps.Geocoder();

    geocoder.geocode({'address': address}, (results, status) => {

      if (status === google.maps.GeocoderStatus.OK) {
        //if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {
          this.setState({
            ...this.state,
            isMapError: false,
            geocodedLocation: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            }
          })
        }

        // Combining the zero-results and not-ok errors into one for now, can put
        // more descriptive error messages up to the screen later if needed  
        // else {
        //   this.setState({
        //     ...this.state,
        //     isMapError: true
        //   })
        // //}
       else {
        this.setState({
          ...this.state,
          isMapError: true
        })
      }
    })
  }


  render() {
    return (
      <div ref='map_panel_content' className="map-panel-container">
        <div className="panel-header">Facility Location</div>
        <div className="map-panel-map">
          {this.state.isMapError ?
            <div className={"map-error"}>Error loading map for this location</div>
            :
            <MapComponent
              geocodedLocation={this.state.geocodedLocation}
            />
          }
        </div>
        <div className="map-panel-address">
        </div>
      </div>
    )
  }
}



