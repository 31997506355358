import * as React from "react"

interface Props {
 fileUrl: string
}

interface State {
  photoIndex: number,
  isOpen: boolean
}

// Handles the rendering of the view library for static images
export class ImageComponent extends React.Component<Props, State> {

  constructor(props: Props ) {
    super(props)
    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  /*
  Due to increased security, we require HTTP Basic Auth. This precludes us
  from loading the images directly into the lightbox, as the username:pass@ format
  is deprecated and will likely load scary warnings.

  Until a solution can be found, this won't work.
  */
  render() {
    return (
      <div className="document-list">
        <a href={this.props.fileUrl} target="_blank" rel="noopener noreferrer"><i className="fa fa-file-image-o document-icon"></i><span>IMG</span></a>
      </div>
    )
  }
}

 
 