import React from 'react';

import './index.css';
import SolarFusion  from "./components/SolarFusion"
import { Auth0Provider } from "./react-auth0-spa";
import history from "./util/history";

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const config = {
  domain: 'solarcoin.auth0.com',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
}

// const url = "https://heuristic-liskov-672f2a.netlify.com" // supply the url of your Netlify site instance with Identity enabled. VERY IMPORTANT

function App() {

  return (
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={process.env.REACT_APP_AUTH0_REDIRECT_URL}
      onRedirectCallback={(onRedirectCallback as unknown as any)}
      audience={config.audience}
    >
      <SolarFusion />
    </Auth0Provider>
  );
}

export default App;
