import * as React from "react"
import * as _ from "lodash"

import {
  Project,
  // ApprovalStatus,
} from "./Interfaces"


interface Props {
  projectData: Project
  updateField(field: string, value: string): void
  isEditMode: boolean
}

/* 
 * This class displays all the important fields for a Project. 
 * It is used in both the project dashboard view as well as
 * the modal editor. 
 * the isEditMode flag determines whether the fields appear
 * as editable inputs/dropdowns or static values
 */

export const ProjectEditFields = (props: Props) => {
  const {
    updateField,
    projectData,
    isEditMode
  } = props

  const handleChange = (field: string, event: React.FormEvent<HTMLInputElement>) => {
    updateField(field, event.currentTarget.value)
  }

  const projectDataColumnLabels = [
    { key: "id", name: "Project ID", editable: false },
    { key: "createdAt", name: "Claim Date", editable: false},
    { key: "nameplate", name: "Nameplate", editable: true},
    { key: "kwhAtClaimingDate", name: "Actual kWh", editable: false},
    { key: "installDate", name: "Install Date", editable: true},
    { key: "walletAddress", name: "Wallet Address", editable: true},
    { key: "bountyClaimant.codename", name: "Affiliate", editable: false},
    { key: "documentation", name: "Docs", editable: true},
  ]

  const projectLocationColumnLabels = [
    { key: "claimant.email", name: "Email", editable: true },
    { key: "claimant.firstName", name: "First Name", editable: true},
    { key: "claimant.lastName", name: "Last Name", editable: true},
    { key: "address", name: "Address", editable: true},
    { key: "city", name: "City", editable: true},
    { key: "state", name: "State", editable: true},
    { key: "zipCode", name: "Postcode", editable: true},
    { key: "country", name: "Country", editable: true},
  ];

  const buildInputFields = (columnArray: any) => {
    return(
      <div className="project-data-fields">
        {columnArray.map((field: {key: string, name: string, editable:boolean}) => {
        return(
          <div className="modal-row" key={field.key}>
            <div className="modal-row-label">{field.name}</div>
            {field.editable ?
              <input
                className="modal-row-input"
                type="text"
                value={_.get(projectData, field.key)}
                onChange={handleChange.bind(null, field.key)}
              />
              :
              <input
                className="modal-row-input"
                type="text"
                value={_.get(projectData, field.key)}
                disabled={true}
              />
            }
          </div>
        )
      })}
    </div>
    )
    }

  const buildStaticFields = (columnArray: any) => {
    return(
      <div className="project-data-fields">
        {columnArray.map((field: {key: string, name: string}) => {
          if (field.key === "documentation") {
            return(
              <div className="modal-row" key={field.key}>
                <div className="modal-row-label">{field.name}</div>
                <div className="modal-row-static-value">
                  <br />
                  {_.get(projectData, field.key)
                }</div>
              </div>
            )
          }
          return(
            <div className="modal-row" key={field.key}>
              <div className="modal-row-label">{field.name}</div>
              <div className="modal-row-static-value">{_.get(projectData, field.key)}</div>
            </div>
          )
        })}
      </div>
    )
  }

  const fieldset = isEditMode ?
    <div className="project-modal-edit">
      {buildInputFields(projectDataColumnLabels)}
      {buildInputFields(projectLocationColumnLabels)}
    </div> :
    <div className="project-modal-edit">
      {buildStaticFields(projectDataColumnLabels)}
      {buildStaticFields(projectLocationColumnLabels)}
    </div>

  return(
    <div>
      {fieldset}
    </div>
  )
}

