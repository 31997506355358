import React, {useState, useEffect} from "react"

import { RedemptionGrantsView } from "./RedemptionGrantsView"
import { getRedemptionGrants } from "./FetchUtilities"
import { useAuth0 } from "../react-auth0-spa"

interface GrantPeriod {
  grantMonth: number
  grantYear: number
}

export const RedemptionsContainer: React.FC = () => {

  const [isError, setIsError] = useState(false)
  const [redemptionGrantsData, setRedemptionGrantsData] = useState([] as any[])
  const { getTokenSilently } = useAuth0()

  // Fetch grants on mount
  useEffect(() => {
    async function fetchRedemptionGrants() {
      const jwtToken = await getTokenSilently()
      const result = await getRedemptionGrants(jwtToken);
      
      if(!result && isError === false) {
        setIsError(true)
      }

      else if (result) {
        // setRedemptionGrantsData(result);
        setRedemptionGrantsData([{
          id: 1,
          createdAt: '2020-11-01 11:44:10.0',
          affiliateId: 1234,
          projectId: 123,
          existingWallet: 12233455543,
          newWallet: 123666666,
          balance: 10,
          status: 'requested',
        }])
      }
    }
    fetchRedemptionGrants()
  }, [getTokenSilently, isError]);

  
  return (
    <RedemptionGrantsView
      redemptionGrantsData = {redemptionGrantsData}
    />
  )
}
