import * as React from "react"

import { LoadingPlaceholder } from "./LoadingPlaceholder"
import { TableType } from "./Enums";
import SearchableProjectsTable from "./SearchableProjectsTable";

interface Props {
  registrationData: any
  approvalStatuses: any //ApprovalStatus[]
}

export const RegistrationView = (props: Props) => {

  return(
    <div className="adjustment-grants-view-container">
      {props.registrationData ?
        <div>
          <SearchableProjectsTable
            inputData={props.registrationData}
            tableType={TableType.REGISTRATIONS}
            changeDisplayType={()=>({})}
            updateActiveProjectId={()=>({})}
            toggleShowModal={()=>({})}
            rejectFromDuplicateModal={()=>({})}
            approvalStatuses={props.approvalStatuses}
            showConfirmModal={()=>({})}
          />
          <div className="row-count">
            Count: {props.registrationData.length}
          </div>
        </div>
        :
        <LoadingPlaceholder />
      }
    </div>
  )
}