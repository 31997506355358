import * as React from "react"

import SearchableProjectsTable from "./SearchableProjectsTable";

import { Project, ApprovalStatus, ApprovalStatusContainer } from "./Interfaces"
import { ProjectViewStyle, ModalTypes, TableType } from "./Enums";

interface Props {
  baseProjectData: Project
  potentialDuplicateProjects: Project[]
  handleCloseModal: () => void
  rejectFromDuplicateModal: (value: string, projectId: string, isBaseProject: boolean) => void
  duplicateApprovalStatus: ApprovalStatus
  updateActiveProjectId: (projectId: string) => void
  changeDisplayType: (displayType: ProjectViewStyle) => void
  toggleShowModal: (modalType?: ModalTypes) => void,
  approvalStatuses: ApprovalStatusContainer
}

interface DuplicateModalProject extends Project {
  shouldShowConfirm: boolean
}

interface State {
  sortedPotentialDuplicateProjects: DuplicateModalProject[]
  showConfirmForBaseProject: boolean
}

export class DuplicatesModal extends React.Component<Props, State> {

  constructor(props:any) {
    super(props)

    // On initial load, sort all potential duplicates by ID, then initialize the shouldShowConfirm to false.
    this.state = {
      sortedPotentialDuplicateProjects: this.buildDuplicateModalProjects(this.props.potentialDuplicateProjects),
      showConfirmForBaseProject: false
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      ...this.state,
      sortedPotentialDuplicateProjects: this.buildDuplicateModalProjects(nextProps.potentialDuplicateProjects)
    });
  }

  buildDuplicateModalProjects = (newProjects: Project[]) => {

    return newProjects.map((potentialDuplicateProject: DuplicateModalProject) => {
      potentialDuplicateProject.shouldShowConfirm = false
      return potentialDuplicateProject
    })
  }

  // When the reject button is clicked for the first time, that row should
  // switch the reject button to 'confirm', in order to avoid accidental
  // rejections.

  // If the base project (the one being used as the key to search for duplicates)
  // is rejected, the duplicates modal should be closed,
  // since the project will no longer be in the Pending list. The duplicates
  // modal operates off the index of a project in the Pending list, so odd
  // behavior would result.

  render() {
    return (
      <div className="project-duplicate-modal">
        <div className="project-duplicate-modal-header">
          Duplicate Check
        <div className="duplicate-modal-close" onClick={() => this.props.toggleShowModal(ModalTypes.DuplicatesModal)}><i className="fa fa-times" aria-hidden="true"></i></div>
      </div>

      <SearchableProjectsTable
            inputData={[this.props.baseProjectData]}
            updateActiveProjectId={this.props.updateActiveProjectId}
            changeDisplayType={this.props.changeDisplayType}
            toggleShowModal={this.props.toggleShowModal}
            tableType={TableType.BASE_PROJECT_VIEW}
            rejectFromDuplicateModal={this.props.rejectFromDuplicateModal}
            approvalStatuses={this.props.approvalStatuses}
            showConfirmModal={()=>({})}
          />

      <SearchableProjectsTable
            inputData={this.props.potentialDuplicateProjects}
            updateActiveProjectId={this.props.updateActiveProjectId}
            changeDisplayType={this.props.changeDisplayType}
            toggleShowModal={this.props.toggleShowModal}
            tableType={TableType.DUPLICATES_VIEW}
            rejectFromDuplicateModal={this.props.rejectFromDuplicateModal}
            approvalStatuses={this.props.approvalStatuses}
            showConfirmModal={()=>({})}
          />
    </div>
    )
  }
}

