/**
 * Top-level application states
 */
export enum ApplicationStates {
  Projects,
  AdjustmentGrants,
  PeriodicGrants,
  Reporting,
  Registrations,
  UploadPayments,
  Loading,
  ProjectData,
  Redemptions
}

export enum AdjustmentGrantTypes {
  Grant,
  NetworkDevelopmentGrant,
  BountyGrant
}

export enum PeriodicGrantTypes {
  Grant,
  NetworkDevelopmentGrant,
  BountyGrant
}

export enum ProjectViewStyle {
  Dashboard,
  Table
}

export enum ReportTypes {
  AllRegistrationsReport
}

export enum ModalTypes {
  ProjectConfirmModal,
  DuplicatesModal
}

export enum TableType {
  PROJECTS_VIEW = "projectsView",
  DUPLICATES_VIEW = "duplicatesView",
  BASE_PROJECT_VIEW = "baseProjectView",
  ADJUSTMENT_GRANTS = "adjustmentGrants",
  BOUNTY_GRANTS = "bountyGrants",
  PERIODIC_GRANTS = "periodicGrants",
  GENERATED_GRANTS = "generatedGrants",
  GRANTS = "grants",
  PROJECTS_SEARCH_RESULT = "projectsSearchResult",
  REGISTRATIONS = "registrations",
  ALL_PROJECTS = "allProjects",
  REDEMPTION_GRANTS = "redemptions",
  NDF_GRANTS = "ndfGrants"
}